export const PHYSIC_NOTE_CREATE_REQUEST = "PHYSIC_NOTE_CREATE_REQUEST";
export const PHYSIC_NOTE_CREATE_SUCCESS = "PHYSIC_NOTE_CREATE_SUCCESS";
export const PHYSIC_NOTE_CREATE_FAIL = "PHYSIC_NOTE_CREATE_FAIL";
export const PHYSIC_NOTE_CREATE_RESET = "PHYSIC_NOTE_CREATE_RESET";

export const PHYSIC_NOTE_LIST_REQUEST = "PHYSIC_NOTE_LIST_REQUEST";
export const PHYSIC_NOTE_LIST_SUCCESS = "PHYSIC_NOTE_LIST_SUCCESS";
export const PHYSIC_NOTE_LIST_FAIL = "PHYSIC_NOTE_LIST_FAIL";
export const PHYSIC_NOTE_LIST_RESET = "PHYSIC_NOTE_LIST_RESET";

export const PHYSIC_NOTE_UPDATE_REQUEST = "PHYSIC_NOTE_UPDATE_REQUEST";
export const PHYSIC_NOTE_UPDATE_SUCCESS = "PHYSIC_NOTE_UPDATE_SUCCESS";
export const PHYSIC_NOTE_UPDATE_FAIL = "PHYSIC_NOTE_UPDATE_FAIL";
export const PHYSIC_NOTE_UPDATE_RESET = "PHYSIC_NOTE_UPDATE_RESET";

export const PHYSIC_NOTE_DELETE_REQUEST = "PHYSIC_NOTE_DELETE_REQUEST";
export const PHYSIC_NOTE_DELETE_SUCCESS = "PHYSIC_NOTE_DELETE_SUCCESS";
export const PHYSIC_NOTE_DELETE_FAIL = "PHYSIC_NOTE_DELETE_FAIL";
export const PHYSIC_NOTE_DELETE_RESET = "PHYSIC_NOTE_DELETE_RESET";
