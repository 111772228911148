import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { listErrTemps } from "../actions/drugActions";
import { ERR_TEMPS_RESET } from "../constants/drugConstants";
import ReviewUserErrTemp from "./ReviewUserErrTemp";

const ReviewTemplateModal = ({
  showTemplates,
  setShowTemplates,
  selectedDgErr,
  setSelectedDgErr,
}) => {
  const [message, setMessage] = useState();

  const errTempsStore = useSelector((state) => state.errTempsStore);
  const { noRequest, loading, errTemps, error } = errTempsStore;

  const handleClose = () => {
    setShowTemplates(false);
    dispatch({ type: ERR_TEMPS_RESET });
    setSelectedDgErr();
    setMessage();
  };
  const copyTemplate = (t) => {
    selectedDgErr.errNote = t.label;
    handleClose();
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedDgErr) {
      if (noRequest && selectedDgErr.errDrug && selectedDgErr.errType) {
        dispatch(
          listErrTemps({
            tradeNameId: selectedDgErr.errDrug,
            errTypeId: selectedDgErr.errType,
          })
        );
      }
    }
    if (error) {
      setMessage(error);
    }
  }, [selectedDgErr, error, noRequest]);
  return (
    <>
      {message && (
        <Message children={message} variant="danger" setMessgae={setMessage} />
      )}
      <Modal
        size="lg"
        onHide={() => handleClose()}
        show={showTemplates}
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Templates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {error && <Message children={error} variant="danger" />}
            {loading && <Loader />}
            {selectedDgErr && (
              <ReviewUserErrTemp
                errDrug={selectedDgErr.errDrug}
                errType={selectedDgErr.errType}
                copyTemplate={copyTemplate}
              />
            )}

            {errTemps &&
              errTemps.map((t) => (
                <div key={t._id} className="row justify-content-center">
                  <div
                    onClick={() => copyTemplate(t)}
                    className="row btn btn-block m-2  btn-outline-warning"
                    key={t._id}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${t.label}`,
                      }}
                    />
                  </div>
                </div>
              ))}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReviewTemplateModal;
