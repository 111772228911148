import React, { lazy, Suspense } from "react";

const LoginComponent = lazy(() => import("../components/LoginComponent"));

const renderLoader = () => <p>Loading</p>;

const HomeScreen = () => {
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <LoginComponent />
      </Suspense>
    </>
  );
};

export default HomeScreen;
