import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTeamDetails } from "../actions/teamActions";
import { intervalToDuration } from "date-fns";
import { registerPatient, listPatient } from "../actions/patientActions";

const PatientRegister = ({
  teamId,
  pageNumber,
  showInpatient,
  editPermission,
}) => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fourthName, setFourthName] = useState("");
  const [birthdate, setBirthdate] = useState("");

  let currentAge = birthdate
    ? intervalToDuration({
        start: new Date(birthdate),
        end: new Date(),
      })
    : {
        days: 0,
        hours: 0,
        minutes: 0,
        months: 0,
        seconds: 0,
        years: 0,
      };

  const patientListStore = useSelector((state) => state.patientListStore);
  const { patients } = patientListStore;

  const newPatientStore = useSelector((state) => state.newPatientStore);
  const { patientInfo } = newPatientStore;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { team } = teamDetails;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!team || teamId !== team._id) {
      dispatch(getTeamDetails(teamId));
    } else {
      dispatch(
        listPatient({
          firstName,
          middleName,
          lastName,
          fourthName,
          birthdate,
          team: teamId,
          pageNumber,
        })
      );
    }
  }, [
    dispatch,
    patientInfo,
    firstName,
    middleName,
    lastName,
    fourthName,
    birthdate,
    teamId,
    team,
    pageNumber,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      registerPatient({
        firstName,
        middleName,
        lastName,
        fourthName,
        birthdate,
        team,
      })
    );
  };

  return (
    <>
      <Form onSubmit={submitHandler}>
        <h2>Serach | Add New Patient</h2>

        <Row className="justify-content-center pb-2">اسم المريض ثلاثي</Row>
        <Row className="flex-row-reverse">
          <Form.Group controlId="firstName" className="col-sm-3">
            <Form.Control
              className="text-center"
              type="name"
              disabled={showInpatient}
              placeholder="اسم المريض"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="middleName" className="col-sm-3">
            <Form.Control
              type="name"
              disabled={showInpatient}
              className="text-center"
              placeholder="الاسم الأب"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="lastName" className="col-sm-3">
            <Form.Control
              type="name"
              className="text-center"
              placeholder="اسم الجد"
              disabled={showInpatient}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="fourthName" className="col-sm-3">
            <Form.Control
              type="name"
              className="text-center"
              placeholder="الاسم الرابع"
              disabled={showInpatient}
              value={fourthName}
              onChange={(e) => setFourthName(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="birthdate">
              <Row className="form-label justify-content-center pb-2">
                تاريخ الميلاد
              </Row>
              <Form.Control
                type="date"
                className="text-center"
                disabled={showInpatient}
                placeholder="Enter Birthdate"
                value={birthdate}
                onChange={(e) => setBirthdate(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center font-weight-bold text-bold">
          {currentAge &&
            `Age now is: ${currentAge.years} years, ${currentAge.months} months and ${currentAge.days}
               days`}
        </Row>
        {editPermission && patients && patients.length === 0 && (
          <Row className=" justify-content-end mr-2 py-2">
            <Button
              className="btn-primary text-white"
              type="submit"
              disabled={showInpatient}
            >
              Add Patient
            </Button>
          </Row>
        )}
      </Form>
    </>
  );
};

export default PatientRegister;
