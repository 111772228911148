import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import Loader from "./Loader";

const ReviewsSearchPerDay = () => {
  const reviewsPerDayStore = useSelector((state) => state.reviewsPerDayStore);
  const { loading, reviewsPerDay } = reviewsPerDayStore;

  useEffect(() => {
    console.log("done");
  }, [reviewsPerDay]);
  return (
    <div>
      {loading && <Loader />}
      {reviewsPerDay && (
        <Table>
          <thead>
            <tr>
              <th>Day</th>
              <th>Total</th>
              <th>Member: {`reviews (Medication Reviewed)`}</th>
            </tr>
          </thead>
          <tbody>
            {reviewsPerDay.map((r) => (
              <tr key={r.day}>
                <td>
                  {format(new Date(r.day), "eeee")}: {r.day}
                </td>
                <td>{r.totalReviews}</td>
                <td>
                  {r.user.map((u) => (
                    <div key={u}>
                      {u.relatedReviews > 0 && (
                        <>
                          {u.user}: {u.relatedReviews} {`(${u.errFreeReviews})`}
                        </>
                      )}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ReviewsSearchPerDay;
