import React from "react";
import ReviewSearchScreen from "./screens/ReviewSearchScreen";
import Test from "./screens/Test";
import About from "./screens/About";
import DrugAdminScreen from "./screens/DrugAdminScreen";
import TeamProfileScreen from "./screens/TeamProfileScreen";
import { ReactNotifications } from "react-notifications-component";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import RegisterScreen from "./screens/RegisterScreen";
import UserProfileScreen from "./screens/UserProfileScreen";
import PatientListScreen from "./screens/PatientListScreen";
import AdmissionListScreen from "./screens/AdmissionListScreen";
import ReviewListScreen from "./screens/ReviewListScreen";
import TeamListScreen from "./screens/TeamListScreen";
import TeamEditScreen from "./screens/TeamEditScreen";
import HomeScreen from "./screens/HomeScreen";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ErrScreen from "./screens/ErrScreen";
import CoursePlayScreen from "./screens/CoursePlayScreen";
import CourseListScreen from "./screens/CourseListScreen";
import ScrollUp from "./components/ScrollUp";
import UserPublicScreen from "./screens/UserPublicScreen";
import YoutubeScreen from "./screens/YoutubeScreen";

const App = () => {
  return (
    <>
      <ReactNotifications />
      <ScrollUp />
      <Router>
        <Header />
        <main className="py-3">
          <Container>
            <Routes>
              <Route path="/profile" element={<UserProfileScreen />} />
              <Route path="/user/:id" element={<UserPublicScreen />} />

              <Route path="/register" element={<RegisterScreen />} />

              <Route path="/teamlist" element={<TeamListScreen />} />
              <Route path="/team/:id" element={<TeamProfileScreen />} />
              <Route path="/teamlist/:id" element={<TeamEditScreen />} />

              <Route path="/patient/:id" element={<AdmissionListScreen />} />
              <Route path="/patientlist/:id" element={<PatientListScreen />} />
              <Route path="/admissions/:id" element={<ReviewListScreen />} />
              <Route path="/" element={<HomeScreen />} />

              <Route path="/drugs" element={<DrugAdminScreen />} />

              <Route path="/test" element={<Test />} />
              <Route path="/about" element={<About />} />

              <Route
                path="/reviewSearch/:id"
                element={<ReviewSearchScreen />}
              />
              <Route
                path="/clinical-pharmacy-courses"
                element={<CourseListScreen />}
              />
              <Route
                path="/clinical-pharmacy-courses/:id"
                element={<CoursePlayScreen />}
              />

              <Route path="/myTube" element={<YoutubeScreen />} />
              <Route path="/*" element={<ErrScreen />} />
            </Routes>
          </Container>
        </main>
        <Footer />
      </Router>
    </>
  );
};

export default App;
