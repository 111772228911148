const courses = [
  {
    _id: "Genebrandex_App",
    title: "Genebrandex App",
    description: "The ever simplest offline clinical pharmacy app ",

    lectures: [
      {
        _id: 1,
        title: "Intro",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1670777385/genebrandex/1_acmbea.mp4",
      },
      {
        _id: 2,
        title: "PART I",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1670777393/genebrandex/2_ukgxdc.mp4",
      },
      {
        _id: 3,
        title: "PART II",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1670843967/genebrandex/3_xxiobs.mp4",
      },
      {
        _id: 4,
        title: "PART III",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1670843780/genebrandex/4_intkd9.mp4",
      },
      {
        _id: 5,
        title: "PART IV",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1670777667/genebrandex/5_kvgulp.mp4",
      },
    ],
  },
  {
    _id: "Hypersensitivity_Test",
    title: "Hypersensitivity Test",
    description: "Start Hypersensitivity test safely ",
    lectures: [
      {
        _id: 1,
        title: "Intro",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1671018163/DHRs/1_DHRs_vrevik.mp4",
      },
      {
        _id: 2,
        title: "PART I",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1671018225/DHRs/2_DHRs_whv9wq.mp4",
      },
      {
        _id: 3,
        title: "PART II",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1671018187/DHRs/3_DHRs_ucjilq.mp4",
      },
      {
        _id: 4,
        title: "PART III",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1671019879/DHRs/4_DHRs_t5nhzv.mp4",
      },
      {
        _id: 5,
        title: "PART IV",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1671019875/DHRs/5_DHRs_twwpgr.mp4",
      },
      {
        _id: 6,
        title: "PART V",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1671020363/DHRs/6_DHRs_btrngu.mp4",
      },
      {
        _id: 7,
        title: "PART VI",
        video:
          "https://res.cloudinary.com/aymanhamoda/video/upload/v1671020417/DHRs/7_DHRs_fl9ojl.mp4",
      },
    ],
  },
];
export default courses;
