import React from "react";
import getPageMargins from "../components/getPageMargins";

export const ComponentToPrint = React.forwardRef(({ review, patient }, ref) => {
  return (
    <div
      className="bg-white text-dark text-bold"
      style={{ fontFamily: "Centaur" }}
      ref={ref}
    >
      <div>
        <style>{getPageMargins()}</style>
        <div style={{ fontSize: "6vw" }}>
          <div>
            {`Patient Name: ${patient.firstName} ${patient.middleName} ${patient.lastName}`}{" "}
            <br />
            {`Review Date: ${
              review.reviewDate && review.reviewDate.substring(0, 10)
            }`}
            <br />
            recommendations:
          </div>
          {review.drugErrs.map((e, i = 0) => (
            <ul key={e._id}>
              <div>
                {i + 1}- {e.errDrugLabel} <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${e.errNote}`,
                  }}
                />
              </div>
            </ul>
          ))}

          <div>
            {`Edited by: ${review.userName}`}
            <span style={{ paddingLeft: "150px" }}>Physcian Signature</span>
          </div>
        </div>
      </div>
    </div>
  );
});
