import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import Message from "./Message";
import PhysicNote from "./PhysicNote";
import { Accordion, Form } from "react-bootstrap";
import { Howl } from "howler";
import {
  PHYSIC_NOTE_CREATE_RESET,
  PHYSIC_NOTE_DELETE_RESET,
  PHYSIC_NOTE_UPDATE_RESET,
} from "../constants/physicConstants";

const PhysicNoteList = ({
  patientId,
  admission,
  labeledNote,
  setLabeledNote,
  synchedNotes,
  setSynchedNotes,
}) => {
  var sound = new Howl({
    src: `https://www.clinicbook.org/success.mp3`,
  });

  const [message, setMessage] = useState("");
  const [expandAll, setExpandAll] = useState();

  const physicNoteListStore = useSelector((state) => state.physicNoteListStore);
  const { loading: loadAdmNote, noteList, error } = physicNoteListStore;

  const physicNoteCreateStore = useSelector(
    (state) => state.physicNoteCreateStore
  );
  const { newNote } = physicNoteCreateStore;

  const physicNoteDeleteStore = useSelector(
    (state) => state.physicNoteDeleteStore
  );
  const { deletedNote } = physicNoteDeleteStore;

  const physicNoteUpdateStore = useSelector(
    (state) => state.physicNoteUpdateStore
  );
  const { loading: loadUpdatedNote, updatedNote } = physicNoteUpdateStore;

  const dispatch = useDispatch();
  const ref = useRef();

  const handleExpand = () => {
    if (!expandAll) {
      setExpandAll("expand");
    } else {
      setExpandAll();
    }
  };

  useEffect(() => {
    if (synchedNotes && labeledNote) {
      const clientHeight = document.getElementById(labeledNote);
      if (clientHeight) {
        clientHeight.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }

    if (!synchedNotes) {
      if (noteList) {
        setSynchedNotes(noteList);
      }
    } else {
      if (newNote) {
        setLabeledNote(newNote._id);
        let list = [...synchedNotes, newNote];
        list = list.sort((a, b) => (a.noteDate > b.noteDate ? 1 : -1));
        setSynchedNotes(list);
        sound.play();
        dispatch({ type: PHYSIC_NOTE_CREATE_RESET });
      }
      if (updatedNote) {
        setLabeledNote(updatedNote._id);
        let list = synchedNotes.filter((r) => {
          return r._id !== updatedNote._id;
        });
        list = [...list, updatedNote];
        list = list.sort((a, b) => (a.noteDate > b.noteDate ? 1 : -1));
        setSynchedNotes(list);
        sound.play();
        dispatch({ type: PHYSIC_NOTE_UPDATE_RESET });
      }
      if (deletedNote) {
        let list = synchedNotes.filter((r) => {
          return r._id !== deletedNote._id;
        });
        setSynchedNotes(list);
        sound.play();
        dispatch({ type: PHYSIC_NOTE_DELETE_RESET });
      }
    }

    if (error) {
      setMessage(error);
    }
  }, [
    synchedNotes,
    noteList,
    newNote,
    updatedNote,
    deletedNote,
    labeledNote,
    error,
  ]);
  return (
    <>
      {message && (
        <Message children={message} variant="danger" setMessgae={setMessage} />
      )}
      {loadAdmNote || loadUpdatedNote ? (
        <Loader />
      ) : (
        <>
          <Form.Check
            className="my-3"
            type="switch"
            id="custom-switch"
            onChange={() => handleExpand()}
            label="Expand All"
          />
          {synchedNotes && (
            <Accordion className="py-2" activeKey={expandAll && expandAll}>
              {synchedNotes.map((n) => (
                <div key={n._id} className="my-2">
                  <PhysicNote
                    noteData={n}
                    admission={admission}
                    labeledNote={labeledNote}
                    patientId={patientId}
                    expandAll={expandAll}
                  />
                </div>
              ))}
            </Accordion>
          )}
        </>
      )}
    </>
  );
};

export default PhysicNoteList;
