import React, { useState } from "react";
import PhysicNoteAccordion from "./PhysicNoteAccordion";
import PhysicNoteEditForm from "./PhysicNoteEditForm";

const PhysicNote = ({
  noteData,
  admission,
  labeledNote,
  patientId,
  expandAll,
}) => {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <div>
      {showEdit ? (
        <PhysicNoteEditForm
          noteData={noteData}
          showEdit={showEdit}
          setShowEdit={setShowEdit}
        />
      ) : (
        <PhysicNoteAccordion
          noteData={noteData}
          admission={admission}
          labeledNote={labeledNote}
          patientId={patientId}
          setShowEdit={setShowEdit}
          showEdit={showEdit}
          expandAll={expandAll}
        />
      )}
    </div>
  );
};

export default PhysicNote;
