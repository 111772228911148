import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { newAdmMed, updateAdmMed } from "../actions/admissionActions";
import { Button, Card, Form, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import {
  NEW_ADM_MED_RESET,
  UPDATE_ADM_MED_RESET,
} from "../constants/admissionConstants";
import Message from "./Message";

const AdmMedForm = ({
  medication,
  medVehicle,
  setMedVehicle,
  tradeLabels,
  refresh,
  setRefresh,
  admissionId,
}) => {
  const admMedNewStore = useSelector((state) => state.admMedNewStore);
  const { newMedication, error } = admMedNewStore;

  const admMedUpdateStore = useSelector((state) => state.admMedUpdateStore);
  const { admissionMedications } = admMedUpdateStore;

  const handleStartDate = (e) => {
    medication.startDate = e.target.value;
  };

  const handleEndDate = (e) => {
    medication.endDate = e.target.value;
  };

  const handleNote = (e) => {
    medication.note = e.target.value;
  };

  const handleTrade = (e) => {
    if (e.length !== 0) {
      medication.medicationTrade = e["0"]._id;
      medication.tradeLabel = e["0"].label;
    }
  };

  const updateMedication = () => {
    medication.edit = "load";
    setRefresh(!refresh);
    dispatch(updateAdmMed(medication));
  };

  const handleNewMed = () => {
    medication.edit = "load";
    dispatch(newAdmMed(medication));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (newMedication) {
      medVehicle = [
        ...medVehicle,
        {
          order: newMedication.order,
          admissionId,
          _id: newMedication._id,
          idx: newMedication.idx,
          medicationTrade: newMedication.medicationTrade,
          tradeLabel: newMedication.tradeLabel,
          medicationRoot: newMedication.medicationRoot,
          startDate: newMedication.startDate,
          endDate: newMedication.endDate,
          note: newMedication.note,
          duration: newMedication.duration,
          edit: false,
        },
      ];
      setMedVehicle(medVehicle.filter((m) => m !== medication));
      setRefresh(!refresh);

      dispatch({ type: NEW_ADM_MED_RESET });
    }

    if (admissionMedications) {
      medVehicle = [
        ...medVehicle,
        {
          order: admissionMedications.order,
          admissionId,
          _id: admissionMedications._id,
          idx: admissionMedications.idx,
          medicationTrade: admissionMedications.medicationTrade,
          tradeLabel: admissionMedications.tradeLabel,
          medicationRoot: admissionMedications.medicationRoot,
          startDate: admissionMedications.startDate,
          endDate: admissionMedications.endDate,
          note: admissionMedications.note,
          duration: admissionMedications.duration,
          edit: false,
        },
      ].sort((a, b) => a.order - b.order);
      setMedVehicle(medVehicle.filter((m) => m !== medication));
      setRefresh(!refresh);

      dispatch({ type: UPDATE_ADM_MED_RESET });
    }
  }, [newMedication, medication, admissionMedications, refresh, medVehicle]);

  return (
    <div>
      {error && <Message children={error} />}

      <Card className="row mt-2 p-2 border-primary">
        {!medication || medication.edit === "load" || !tradeLabels ? (
          <Skeleton height="100px" />
        ) : (
          <>
            <Row>
              <Form.Group className="col-sm-12">
                <Form.Label>Medication</Form.Label>
                <Typeahead
                  id={"Medication Name"}
                  placeholder="Enter Medication"
                  options={tradeLabels}
                  defaultInputValue={medication.tradeLabel}
                  onChange={(e) => handleTrade(e)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-sm-6" controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={
                    medication &&
                    medication.startDate &&
                    medication.startDate.substring(0, 10)
                  }
                  onChange={(e) => handleStartDate(e)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="endDate" className="col-sm-6">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={
                    medication &&
                    medication.endDate &&
                    medication.endDate.substring(0, 10)
                  }
                  onChange={(e) => handleEndDate(e)}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="note" className="col-sm-12">
                <Form.Label>Notes</Form.Label>
                <textarea
                  className="form-control"
                  rows="3"
                  onChange={(e) => handleNote(e)}
                  defaultValue={medication.note}
                ></textarea>
              </Form.Group>
            </Row>
            <Row className="row justify-content-end mr-3">
              {medication && medication.idx === 0 ? (
                <Button onClick={() => handleNewMed(medication)}>Add</Button>
              ) : (
                <Button onClick={() => updateMedication(medication)}>
                  Update
                </Button>
              )}
            </Row>
          </>
        )}
      </Card>
    </div>
  );
};

export default AdmMedForm;
