import {
  RECAPTCHA_CONSTANT_FAIL,
  RECAPTCHA_CONSTANT_REQUEST,
  RECAPTCHA_CONSTANT_RESET,
  RECAPTCHA_CONSTANT_SUCCESS,
} from "../constants/recaptchaConstants";
import { USER_LOGOUT } from "../constants/userConstants";
export const recaptchaConstantReducer = (
  state = { noRequest: true },
  action
) => {
  switch (action.type) {
    case RECAPTCHA_CONSTANT_REQUEST:
      return { loading: true };
    case RECAPTCHA_CONSTANT_SUCCESS:
      return { loading: false, siteKey: action.payload };
    case RECAPTCHA_CONSTANT_FAIL:
      return { loading: false, error: action.payload };
    case RECAPTCHA_CONSTANT_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};
