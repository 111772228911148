import {
  PATIENT_DETAILS_FAIL,
  PATIENT_DETAILS_REQUEST,
  PATIENT_DETAILS_SUCCESS,
  PATIENT_LIST_FAIL,
  PATIENT_LIST_REQUEST,
  PATIENT_LIST_SUCCESS,
  PATIENT_REGISTER_REQUEST,
  PATIENT_REGISTER_SUCCESS,
  PATIENT_REGISTER_FAIL,
  PATIENT_UPDATE_REQUEST,
  PATIENT_UPDATE_SUCCESS,
  PATIENT_UPDATE_FAIL,
  PATIENT_UPDATE_RESET,
  PATIENT_DETAILS_RESET,
  PATIENT_LIST_RESET,
  PATIENT_DELETE_REQUEST,
  PATIENT_DELETE_SUCCESS,
  PATIENT_DELETE_FAIL,
  PATIENT_DELETE_RESET,
} from "../constants/patientConstants";

export const newPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_REGISTER_REQUEST:
      return { loading: true };
    case PATIENT_REGISTER_SUCCESS:
      return { loading: false, patientInfo: action.payload };
    case PATIENT_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const PatientDeletedReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case PATIENT_DELETE_REQUEST:
      return { loading: true };
    case PATIENT_DELETE_SUCCESS:
      return { loading: false, message: action.payload.message };
    case PATIENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PATIENT_DELETE_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const patientDetailsReducer = (
  state = { patient: {}, noRequest: true },
  action
) => {
  switch (action.type) {
    case PATIENT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PATIENT_DETAILS_SUCCESS:
      return { loading: false, patient: action.payload };
    case PATIENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PATIENT_DETAILS_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const patientListReducer = (
  state = { loading: false, patients: [], noRequest: true },
  action
) => {
  switch (action.type) {
    case PATIENT_LIST_REQUEST:
      return { loading: true, patients: [] };
    case PATIENT_LIST_SUCCESS:
      return {
        loading: false,
        patients: action.payload.patients,
        inpatients: action.payload.inpatients,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PATIENT_LIST_FAIL:
      return { loading: false, error: action.payload, noRequest: true };
    case PATIENT_LIST_RESET:
      return { loading: false, patients: [], noRequest: true };
    default:
      return state;
  }
};

export const patientUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_UPDATE_REQUEST:
      return { loading: true };
    case PATIENT_UPDATE_SUCCESS:
      return { loading: false, updatedPatient: action.payload };
    case PATIENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PATIENT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
