import axios from "axios";
import {
  ADMISSION_CREATE_REQUEST,
  ADMISSION_CREATE_SUCCESS,
  ADMISSION_CREATE_FAIL,
  ADMISSION_DETAILS_REQUEST,
  ADMISSION_DETAILS_SUCCESS,
  ADMISSION_DETAILS_FAIL,
  ADMISSION_LIST_SUCCESS,
  ADMISSION_LIST_REQUEST,
  ADMISSION_UPDATE_REQUEST,
  ADMISSION_UPDATE_SUCCESS,
  ADMISSION_UPDATE_FAIL,
  ADMISSION_DELETE_FAIL,
  ADMISSION_DELETE_SUCCESS,
  ADMISSION_DELETE_REQUEST,
  ADMISSION_LIST_FAIL,
  ADMISSION_RECURRENT_REQUEST,
  ADMISSION_RECURRENT_SUCCESS,
  ADMISSION_RECURRENT_FAIL,
  UPDATE_ADM_MED_REQUEST,
  UPDATE_ADM_MED_FAIL,
  UPDATE_ADM_MED_SUCCESS,
  NEW_ADM_MED_REQUEST,
  NEW_ADM_MED_SUCCESS,
  NEW_ADM_MED_FAIL,
  DELETE_ADM_MED_REQUEST,
  DELETE_ADM_MED_SUCCESS,
  DELETE_ADM_MED_FAIL,
  SORT_ADM_MED_REQUEST,
  SORT_ADM_MED_SUCCESS,
  SORT_ADM_MED_FAIL,
} from "../constants/admissionConstants";
import { logout } from "./userActions";

export const createAdmission = (admission) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMISSION_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/admissions`, admission, config);

    dispatch({
      type: ADMISSION_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ADMISSION_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateAdmission = (admission) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMISSION_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/admissions/${admission._id}/edit`,
      admission,
      config
    );

    dispatch({
      type: ADMISSION_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ADMISSION_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const updateAdmMed = (med) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_ADM_MED_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/admissions/medications/${med.admissionId}`,
      { updatedMed: med },
      config
    );

    dispatch({
      type: UPDATE_ADM_MED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: UPDATE_ADM_MED_FAIL,
      payload: message,
    });
  }
};

export const newAdmMed = (medication) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEW_ADM_MED_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/admissions/medications/${medication.admissionId}`,
      { newMed: medication },
      config
    );

    dispatch({
      type: NEW_ADM_MED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: NEW_ADM_MED_FAIL,
      payload: message,
    });
  }
};

export const deleteAdmMed = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_ADM_MED_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.delete(
      `/api/admissions/medications/${id}`,

      config
    );

    dispatch({
      type: DELETE_ADM_MED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: DELETE_ADM_MED_FAIL,
      payload: message,
    });
  }
};

export const listAdmissions = (patientId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMISSION_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        patient: patientId,
      },
    };
    const { data } = await axios.get(`/api/admissions/${patientId}`, config);
    dispatch({
      type: ADMISSION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ADMISSION_LIST_FAIL,
      payload: message,
    });
  }
};

export const listRecurrentAdmissions =
  (teamId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMISSION_RECURRENT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          team: teamId,
        },
      };
      const { data } = await axios.get(
        `/api/admissions/getRecurrentAdmissions?team=${teamId}`,
        config
      );
      dispatch({
        type: ADMISSION_RECURRENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: ADMISSION_RECURRENT_FAIL,
        payload: message,
      });
    }
  };

export const getAdmissionDetails =
  (admissionId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMISSION_DETAILS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/admissions/${admissionId}/details`,
        config
      );

      dispatch({
        type: ADMISSION_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: ADMISSION_DETAILS_FAIL,
        payload: message,
      });
    }
  };

export const deleteAdmission = (admissionId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMISSION_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.delete(
      `/api/admissions/${admissionId}`,
      config
    );

    dispatch({
      type: ADMISSION_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // if (message === 'Not authorized, token failed') {
    //   dispatch(logout())
    // }
    dispatch({
      type: ADMISSION_DELETE_FAIL,
      payload: message,
    });
  }
};

export const sortAdmMed = (prop) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SORT_ADM_MED_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/admissions/medications/${prop.admissionId}/sort`,
      { medications: prop.sortedMedications },
      config
    );

    dispatch({
      type: SORT_ADM_MED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SORT_ADM_MED_FAIL,
      payload: message,
    });
  }
};
