const dischargeStatusList = [
  { _id: "", label: "Select" },
  { _id: 0, label: "Improved and discharged" },
  { _id: 1, label: "Improved and transfered to lower level unit" },
  { _id: 2, label: "Not Improved and transfered to higher level unit" },
  { _id: 3, label: "Transferred to similar level unit" },
  { _id: 4, label: "DAMA" },
  { _id: 5, label: "Died" },
  { _id: 6, label: "Other" },
];
export default dischargeStatusList;
