import React from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";

const PasswordChecker = ({ password }) => {
  return (
    <Container>
      <Row className="my-2">
        <Col className="col-sm-3 my-2  ">
          <ProgressBar
            label={/[0-9]/.test(password) ? "Contains number" : "NO number"}
            now={100}
            min={0}
            max={100}
            variant={/[0-9]/.test(password) ? "success" : "danger"}
          />
        </Col>
        <Col className="col-sm-3 my-2  ">
          <ProgressBar
            label={
              /[a-z]/.test(password) ? "Contains Lowercase" : "NO Lowercase"
            }
            now={100}
            min={0}
            max={100}
            variant={/[a-z]/.test(password) ? "success" : "danger"}
          />
        </Col>
        <Col className="col-sm-3 my-2  ">
          <ProgressBar
            label={
              /[A-Z]/.test(password) ? "Contains Uppercase" : "NO uppercase"
            }
            now={100}
            min={0}
            max={100}
            variant={/[A-Z]/.test(password) ? "success" : "danger"}
          />
        </Col>
        <Col className="col-sm-3 my-2  ">
          <ProgressBar
            label={password.length > 5 ? "Long enough" : "Too short"}
            now={100}
            min={0}
            max={100}
            variant={password.length > 5 ? "success" : "danger"}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordChecker;
