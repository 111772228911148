import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  Button,
  ButtonToolbar,
  Form,
  FormGroup,
  Card,
  Row,
} from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Typeahead } from "react-bootstrap-typeahead";
import { createReview } from "../actions/reviewActions";
import Loader from "./Loader";
import Message from "./Message";
import ReviewTemplateModal from "./ReviewTemplateModal";
import resTypes from "../data/resTypes";
import TextEditor from "./TextEditor";
import { listTradeLabels } from "../actions/drugActions";
import severityScale from "../data/severityScale";

const ReviewForm = ({ admissionId, patientId, teamId, errTypeList }) => {
  const [reviewDate, setReviewDate] = useState(new Date());
  const [clinicalNote, setClinicalNote] = useState("");
  const [drugErrs, setDrugErrs] = useState();
  const [message, setMessage] = useState("");
  const [showTemplates, setShowTemplates] = useState(false);
  const [selectedDgErr, setSelectedDgErr] = useState();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const reviewAddStore = useSelector((state) => state.reviewAddStore);
  const {
    noRequest,
    loading: loadingNewReview,
    newReview,
    error,
  } = reviewAddStore;

  const tradeLabelsStore = useSelector((state) => state.tradeLabelsStore);
  const { noRequest: noTradeLabelRequest } = tradeLabelsStore;
  const dispatch = useDispatch();

  if (!localStorage.tradeLabels && noTradeLabelRequest) {
    dispatch(listTradeLabels());
  }

  let tradeLabels =
    localStorage.tradeLabels && JSON.parse(localStorage.tradeLabels);

  const submitHandler = (e) => {
    e.preventDefault();
    if (noRequest) {
      dispatch(
        createReview({
          admissionId,
          userId: userInfo._id,
          patientId,
          teamId,
          reviewDate,
          clinicalNote,
          drugErrs,
        })
      );
    }
  };

  const handleDrug = (e, dgErr) => {
    if (e.length !== 0) {
      dgErr.errDrug = e["0"]._id;
    }
  };

  const handleErrType = (e, dgErr) => {
    dgErr.errType = e.target.value;
    dgErr.errTypeLabel = e.target.label;
  };

  const handleErrResp = (e, dgErr) => {
    dgErr.errRes = e.target.value;
    dgErr.errResLabel = e.target.label;
  };

  const handleErrSeverity = (e, dgErr) => {
    dgErr.errSeverity = e.target.value;
    dgErr.errSeverityLabel = e.target.label;
  };

  const handleErrNote = (data, dgErr) => {
    dgErr.errNote = data;
  };
  const addNewRow = () => {
    if (drugErrs) {
      setDrugErrs([
        ...drugErrs,
        {
          idx: Math.random(),
          errDrug: "",
          errType: "",
          errNote: "",
          errRes: "",
          errSeverity: "",
        },
      ]);
    } else {
      setDrugErrs([
        {
          idx: Math.random(),
          errDrug: "",
          errType: "",
          errNote: "",
          errRes: "",
          errSeverity: "",
        },
      ]);
    }
  };
  const deleteRow = (dgErr) => {
    setDrugErrs(drugErrs.filter((e) => e !== dgErr));
  };

  const modalTemplates = (e) => {
    setShowTemplates(!showTemplates);
    setSelectedDgErr(e);
  };

  useEffect(() => {
    if (error) {
      setMessage(error);
    }
    if (newReview) {
      setReviewDate("");
      setDrugErrs([
        {
          idx: Math.random(),
          errDrug: "",
          errType: "",
          errNote: "",
          errRes: "",
          errSeverity: "",
        },
      ]);
    }
  }, [newReview, error]);
  return (
    <>
      <ReviewTemplateModal
        showTemplates={showTemplates}
        setShowTemplates={() => setShowTemplates()}
        selectedDgErr={selectedDgErr}
        setSelectedDgErr={setSelectedDgErr}
      />
      <Accordion className="mt-3" defaultActiveKey="0">
        <Accordion.Toggle
          className="bg-secondary text-white"
          eventKey="0"
          as={Card.Header}
          variant="link"
        >
          <Row className="justify-content-center">
            <i className="fas fa-angle-double-down pt-1 pr-2"></i>CREATE NEW
            REVIEW
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Form>
            {!tradeLabels || loadingNewReview ? (
              <Loader />
            ) : (
              <>
                <Form.Group controlId="reviewDate">
                  <Form.Label>
                    <h2>Review Date</h2>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={reviewDate}
                    onChange={(e) => setReviewDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="clinicalNote">
                  <Form.Label>
                    <h2>Clinical Notes</h2>
                  </Form.Label>
                  <div className="text-dark">
                    {" "}
                    <TextEditor text={clinicalNote} setText={setClinicalNote} />
                  </div>
                </Form.Group>

                {drugErrs &&
                  drugErrs.map((dgErr) => (
                    <div key={dgErr.idx} className="row py-3">
                      <Form.Group className="col-sm-6">
                        <Form.Label>Drug Of Err</Form.Label>
                        <Typeahead
                          placeholder="Enter Medication"
                          options={tradeLabels}
                          onChange={(e) => handleDrug(e, dgErr)}
                          id="errDrug"
                        />
                      </Form.Group>
                      {errTypeList && (
                        <FormGroup className="col-sm-6">
                          <Form.Label>Type Of Err</Form.Label>
                          <select
                            className="custom-select"
                            type="name"
                            value={dgErr.errTypeLabel}
                            onChange={(e) => handleErrType(e, dgErr)}
                          >
                            {errTypeList.map((t) => (
                              <option key={t._id} value={t._id}>
                                {t.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      )}
                      <Form.Group className="col-sm-12">
                        <Form.Label>Error Notes</Form.Label>
                        <div className="text-dark">
                          {" "}
                          <CKEditor
                            editor={ClassicEditor}
                            data={dgErr.errNote}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              handleErrNote(data, dgErr);
                            }}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="col-sm-12">
                        <Form.Label>Response</Form.Label>
                        <select
                          className="custom-select"
                          type="name"
                          defaultValue={""}
                          value={dgErr.errResLabel}
                          onChange={(e) => handleErrResp(e, dgErr)}
                        >
                          {resTypes.map((r) => (
                            <option
                              key={r._id}
                              value={r._id}
                              disabled={r.disabled ? true : false}
                            >
                              {r.label}
                            </option>
                          ))}
                        </select>
                      </Form.Group>
                      <Form.Group className="col-sm-12">
                        <Form.Label>Error Severity</Form.Label>
                        <select
                          className="custom-select"
                          type="name"
                          defaultValue={""}
                          value={dgErr.errSeverityLabel}
                          onChange={(e) => handleErrSeverity(e, dgErr)}
                        >
                          {severityScale.map((r) => (
                            <option
                              key={r._id}
                              value={r._id}
                              disabled={r.disabled ? true : false}
                            >
                              {r.label}
                            </option>
                          ))}
                        </select>
                      </Form.Group>
                      <ButtonToolbar className="container justify-content-center">
                        <Button
                          className="ml-3"
                          variant="warning"
                          onClick={() => modalTemplates(dgErr)}
                        >
                          Show Templates
                        </Button>

                        <Button
                          className="btn btn-danger ml-3"
                          onClick={() => deleteRow(dgErr)}
                        >
                          <i className="fa fa-minus" aria-hidden="true" />
                        </Button>
                      </ButtonToolbar>
                    </div>
                  ))}
                <Row className="ml-2">
                  <Button onClick={() => addNewRow()}>
                    <i className="fa fa-plus-circle" aria-hidden="true"></i> Add
                    Error
                  </Button>
                </Row>
                <div className="row justify-content-end">
                  <Button
                    onClick={(e) => submitHandler(e)}
                    className="btn-primary"
                  >
                    Add Review
                  </Button>
                </div>
              </>
            )}
          </Form>
        </Accordion.Collapse>
      </Accordion>
      {message && (
        <Message children={message} variant="danger" setMessgae={setMessage} />
      )}
    </>
  );
};

export default ReviewForm;
