import React, { useEffect, useRef, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useSelector } from "react-redux";

const UploadWidget = ({ images, setImages, title, gallery, setGallery }) => {
  const [image, setImage] = useState();

  const teamDetails = useSelector((state) => state.teamDetails);
  const { team } = teamDetails;

  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    if (team) {
      cloudinaryRef.current = window.cloudinary;
      widgetRef.current = cloudinaryRef.current.createUploadWidget(
        {
          cloudName: team.cloudName,
          uploadPreset: team.uploadPreset,
        },
        (error, result) => {
          if (result && result.info && result.info.secure_url) {
            setImage(result.info.secure_url);
          }
        }
      );
    }

    setImages(images.length > 0 ? [...images, { image }] : [{ image }]);
  }, [team, image]);
  return (
    <div>
      {team && team.uploadPreset && (
        <Button className="ml-3" onClick={() => widgetRef.current.open()}>
          Upload
        </Button>
      )}
      {images.length > 0 &&
        images.map((i) => (
          <div key={i}>
            <Image src={i.image} fluid />
          </div>
        ))}
    </div>
  );
};

export default UploadWidget;
