import {
  REVIEW_CREATE_REQUEST,
  REVIEW_CREATE_SUCCESS,
  REVIEW_CREATE_FAIL,
  REVIEW_SEARCH_SUCCESS,
  REVIEW_SEARCH_FAIL,
  REVIEW_SEARCH_REQUEST,
  REVIEW_SEARCH_RESET,
  REVIEW_CREATE_RESET,
  REVIEW_DELETE_REQUEST,
  REVIEW_DELETE_SUCCESS,
  REVIEW_DELETE_FAIL,
  REVIEW_DELETE_RESET,
  ERR_TYPE_DIST_REQUEST,
  ERR_TYPE_DIST_RESET,
  ERR_TYPE_DIST_FAIL,
  ERR_TYPE_DIST_SUCCESS,
  LIST_REVEWS_PER_DAY_REQUEST,
  LIST_REVEWS_PER_DAY_SUCCESS,
  LIST_REVEWS_PER_DAY_FAIL,
  LIST_REVEWS_PER_DAY_RESET,
  ADMISSION_REVIEWS_REQUEST,
  ADMISSION_REVIEWS_SUCCESS,
  ADMISSION_REVIEWS_FAIL,
  ADMISSION_REVIEWS_RESET,
  RATING_ERR_REQUEST,
  RATING_ERR_SUCCESS,
  RATING_ERR_FAIL,
  RATING_ERR_RESET,
  REVIEW_SEARCH_PROGRESS_REQUEST,
  REVIEW_SEARCH_PROGRESS_SUCCESS,
  REVIEW_SEARCH_PROGRESS_FAIL,
  REVIEW_SEARCH_PROGRESS_RESET,
  REVIEWS_PER_TAG_REQUEST,
  REVIEWS_PER_TAG_SUCCESS,
  REVIEWS_PER_TAG_FAIL,
  REVIEWS_PER_TAG_RESET,
  REV_MED_DASHBOARD_REQUEST,
  REV_MED_DASHBOARD_SUCCESS,
  REV_MED_DASHBOARD_FAIL,
  REV_MED_DASHBOARD_RESET,
  REV_HEAD_UPDATE_REQUEST,
  REV_HEAD_UPDATE_SUCCESS,
  REV_HEAD_UPDATE_FAIL,
  REV_HEAD_UPDATE_RESET,
  REV_MED_UPDATE_REQUEST,
  REV_MED_UPDATE_SUCCESS,
  REV_MED_UPDATE_FAIL,
  REV_MED_UPDATE_RESET,
  REV_MED_ADD_REQUEST,
  REV_MED_ADD_SUCCESS,
  REV_MED_ADD_FAIL,
  REV_MED_ADD_RESET,
  REV_MED_DEL_REQUEST,
  REV_MED_DEL_SUCCESS,
  REV_MED_DEL_FAIL,
  REV_MED_DEL_RESET,
} from "../constants/reviewConstants";

export const reviewCreateReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case REVIEW_CREATE_REQUEST:
      return { loading: true };
    case REVIEW_CREATE_SUCCESS:
      return { loading: false, newReview: action.payload };
    case REVIEW_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case REVIEW_CREATE_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const reviewSearchReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case REVIEW_SEARCH_REQUEST:
      return { loading: true };
    case REVIEW_SEARCH_SUCCESS:
      return {
        loading: false,
        reviews: action.payload.result,
        csv: action.payload.csv,
      };
    case REVIEW_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    case REVIEW_SEARCH_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const reviewsPerTagReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case REVIEWS_PER_TAG_REQUEST:
      return { loading: true };
    case REVIEWS_PER_TAG_SUCCESS:
      return { loading: false, reviewsPerTag: action.payload };
    case REVIEWS_PER_TAG_FAIL:
      return { loading: false, error: action.payload };
    case REVIEWS_PER_TAG_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const reviewSearchProgressReducer = (
  state = { noRequest: true },
  action
) => {
  switch (action.type) {
    case REVIEW_SEARCH_PROGRESS_REQUEST:
      return { loading: true };
    case REVIEW_SEARCH_PROGRESS_SUCCESS:
      return { loading: false, totalDocs: action.payload };
    case REVIEW_SEARCH_PROGRESS_FAIL:
      return { loading: false, error: action.payload };
    case REVIEW_SEARCH_PROGRESS_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const admissionReviewReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case ADMISSION_REVIEWS_REQUEST:
      return { loading: true };
    case ADMISSION_REVIEWS_SUCCESS:
      return { loading: false, reviews: action.payload };
    case ADMISSION_REVIEWS_FAIL:
      return { loading: false, error: action.payload };
    case ADMISSION_REVIEWS_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const errRatingReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case RATING_ERR_REQUEST:
      return { loading: true };
    case RATING_ERR_SUCCESS:
      return { loading: false, result: action.payload };
    case RATING_ERR_FAIL:
      return { loading: false, error: action.payload };
    case RATING_ERR_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const revHeadUpdateReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case REV_HEAD_UPDATE_REQUEST:
      return { loading: true, reqRevId: action.payload };
    case REV_HEAD_UPDATE_SUCCESS:
      return { loading: false, updatedHeadId: action.payload };
    case REV_HEAD_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case REV_HEAD_UPDATE_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const revMedUpdateReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case REV_MED_UPDATE_REQUEST:
      return { loading: true, reqMedId: action.payload };
    case REV_MED_UPDATE_SUCCESS:
      return { loading: false, updatedMedId: action.payload };
    case REV_MED_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case REV_MED_UPDATE_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const revMedAddReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case REV_MED_ADD_REQUEST:
      return { loading: true };
    case REV_MED_ADD_SUCCESS:
      return { loading: false, newMedId: action.payload };
    case REV_MED_ADD_FAIL:
      return { loading: false, error: action.payload };
    case REV_MED_ADD_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const revMedDelReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case REV_MED_DEL_REQUEST:
      return { loading: true };
    case REV_MED_DEL_SUCCESS:
      return { loading: false, deletedMedId: action.payload };
    case REV_MED_DEL_FAIL:
      return { loading: false, error: action.payload };
    case REV_MED_DEL_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const reviewDeleteReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case REVIEW_DELETE_REQUEST:
      return { loading: true };
    case REVIEW_DELETE_SUCCESS:
      return { loading: false, deletedReview: action.payload };
    case REVIEW_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case REVIEW_DELETE_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const errTypeDistributionReducer = (
  state = { noRequest: true },
  action
) => {
  switch (action.type) {
    case ERR_TYPE_DIST_REQUEST:
      return { loading: true };
    case ERR_TYPE_DIST_SUCCESS:
      return { loading: false, errTypeDist: action.payload };
    case ERR_TYPE_DIST_FAIL:
      return { loading: false, error: action.payload };
    case ERR_TYPE_DIST_RESET:
      return {};
    default:
      return state;
  }
};

export const reviewListPerDayReducer = (
  state = { noRequest: true },
  action
) => {
  switch (action.type) {
    case LIST_REVEWS_PER_DAY_REQUEST:
      return { loading: true };
    case LIST_REVEWS_PER_DAY_SUCCESS:
      return { loading: false, reviewsPerDay: action.payload };
    case LIST_REVEWS_PER_DAY_FAIL:
      return { loading: false, error: action.payload };
    case LIST_REVEWS_PER_DAY_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const revMedDashboardReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case REV_MED_DASHBOARD_REQUEST:
      return { loading: true };
    case REV_MED_DASHBOARD_SUCCESS:
      return { loading: false, medDashboard: action.payload };
    case REV_MED_DASHBOARD_FAIL:
      return { loading: false, error: action.payload };
    case REV_MED_DASHBOARD_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};
