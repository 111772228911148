import React, { lazy, Suspense } from "react";
import CourseList_Lazy from "../components/CourseList_lazy";

const CourseList = lazy(() => import("../components/CourseList_lazy"));

const renderLoader = () => <p>Loading</p>;

const CourseListScreen = () => {
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <CourseList />
      </Suspense>
    </>
  );
};

export default CourseListScreen;
