import React from "react";
import { Card, Image, Row } from "react-bootstrap";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import courses from "../data/courses";
import { useNavigate } from "react-router-dom";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";

const CourseList_Lazy = () => {
  const navigate = useNavigate();
  return (
    <FormContainer>
      <Meta
        title="Clinical Pharmacy Courses"
        description="Clinical pharmacy courses: Carefully designed courses to target fields of clinical pharmacy, including pharmacotherapy from the practice perspective. Start clinical pharmacy practice from a point close to success"
      />
      <BreadcrumbComponent
        titles={[
          { idx: "1", label: "Home", path: `/`, active: false },
          {
            idx: "2",
            label: `Clinical Pharmacy Courses`,
            path: "",
            active: true,
          },
        ]}
      />{" "}
      <Row
        className="p-3 justify-content-center text-center"
        onClick={() => {
          window.open(
            "https://www.udemy.com/course/worshope/?referralCode=D89A996599AD6702C170"
          );
        }}
      >
        <h2 style={{ color: "#00abff" }}>ICU Clinical Pharmacy WORKSHOP </h2>
        <p style={{ fontSize: "3vw" }}>
          أضخم مشروع تعليمي أونلاين لإعداد صيدلي إكلينيكي متميز في العناية
          المركزة
        </p>
        <Image shape="thumbnail" src="/promotion.jpg" fluid />
        <p className="py-3" style={{ fontSize: "3vw", color: "#00abff" }}>
          شاهد المراجعات
        </p>
      </Row>
      <h2 className="px-3">Free Clinical Pharmacy Courses </h2>
      {courses.map((c) => (
        <Card className="row justify-content-center m-3" key={c._id}>
          <Card.Header>
            {" "}
            <h2 style={{ color: "#00abff" }}>{c.title} </h2>
          </Card.Header>
          <Card.Body>
            <p style={{ fontSize: "3vw" }}>{c.description}</p>
          </Card.Body>
          <Card.Footer>
            <p
              className="py-3 justify-content-center"
              style={{ fontSize: "3vw", color: "#00abff" }}
              onClick={() => navigate(`/clinical-pharmacy-courses/${c._id}`)}
            >
              Watch Now for Free!{" "}
            </p>
          </Card.Footer>
        </Card>
      ))}
    </FormContainer>
  );
};

export default CourseList_Lazy;
