import axios from "axios";
import {
  REVIEW_CREATE_REQUEST,
  REVIEW_CREATE_SUCCESS,
  REVIEW_CREATE_FAIL,
  REVIEW_SEARCH_SUCCESS,
  REVIEW_SEARCH_FAIL,
  REVIEW_SEARCH_REQUEST,
  REVIEW_DELETE_REQUEST,
  REVIEW_DELETE_SUCCESS,
  REVIEW_DELETE_FAIL,
  ERR_TYPE_DIST_REQUEST,
  ERR_TYPE_DIST_SUCCESS,
  ERR_TYPE_DIST_FAIL,
  LIST_REVEWS_PER_DAY_REQUEST,
  LIST_REVEWS_PER_DAY_SUCCESS,
  LIST_REVEWS_PER_DAY_FAIL,
  ADMISSION_REVIEWS_REQUEST,
  ADMISSION_REVIEWS_SUCCESS,
  ADMISSION_REVIEWS_FAIL,
  RATING_ERR_REQUEST,
  RATING_ERR_SUCCESS,
  RATING_ERR_FAIL,
  REVIEW_SEARCH_PROGRESS_REQUEST,
  REVIEW_SEARCH_PROGRESS_SUCCESS,
  REVIEW_SEARCH_PROGRESS_FAIL,
  REVIEWS_PER_TAG_REQUEST,
  REVIEWS_PER_TAG_SUCCESS,
  REVIEWS_PER_TAG_FAIL,
  REV_MED_DASHBOARD_REQUEST,
  REV_MED_DASHBOARD_SUCCESS,
  REV_MED_DASHBOARD_FAIL,
  REV_HEAD_UPDATE_REQUEST,
  REV_HEAD_UPDATE_SUCCESS,
  REV_HEAD_UPDATE_FAIL,
  REV_MED_UPDATE_REQUEST,
  REV_MED_UPDATE_SUCCESS,
  REV_MED_UPDATE_FAIL,
  REV_MED_ADD_REQUEST,
  REV_MED_ADD_SUCCESS,
  REV_MED_ADD_FAIL,
  REV_MED_DEL_REQUEST,
  REV_MED_DEL_SUCCESS,
  REV_MED_DEL_FAIL,
} from "../constants/reviewConstants";
import { logout } from "./userActions";

//Review Edit
export const createReview = (review) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REVIEW_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/reviews/addReview`,
      { review },
      config
    );

    dispatch({
      type: REVIEW_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // if (message === 'Not authorized, token failed') {
    //   dispatch(logout())
    // }
    dispatch({
      type: REVIEW_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateRevHead = (review) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REV_HEAD_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/reviews/updateRevHead/${review.reviewId}`,
      review,
      config
    );

    dispatch({
      type: REV_HEAD_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // if (message === 'Not authorized, token failed') {
    //   dispatch(logout())
    // }
    dispatch({
      type: REV_HEAD_UPDATE_FAIL,
      payload: message,
    });
  }
};
export const updateRevMed = (review) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REV_MED_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/reviews/updateRevMed/${review.reviewId}`,
      review,
      config
    );

    dispatch({
      type: REV_MED_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // if (message === 'Not authorized, token failed') {
    //   dispatch(logout())
    // }
    dispatch({
      type: REV_MED_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const newRevMed = (review) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REV_MED_ADD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/reviews/addRevMed/${review.reviewId}`,
      review,
      config
    );

    dispatch({
      type: REV_MED_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // if (message === 'Not authorized, token failed') {
    //   dispatch(logout())
    // }
    dispatch({
      type: REV_MED_ADD_FAIL,
      payload: message,
    });
  }
};

export const delRevMed = (review) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REV_MED_DEL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/reviews/remRevMed/${review.reviewId}`,
      review,
      config
    );

    dispatch({
      type: REV_MED_DEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // if (message === 'Not authorized, token failed') {
    //   dispatch(logout())
    // }
    dispatch({
      type: REV_MED_DEL_FAIL,
      payload: message,
    });
  }
};

export const rateErr = (prop) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RATING_ERR_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/reviews/${prop.reviewId}/editRating`,
      prop,
      config
    );

    dispatch({
      type: RATING_ERR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: RATING_ERR_FAIL,
      payload: message,
    });
  }
};

export const deleteReview = (reviewId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REVIEW_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.delete(`/api/reviews/${reviewId}`, config);

    dispatch({
      type: REVIEW_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // if (message === 'Not authorized, token failed') {
    //   dispatch(logout())
    // }
    dispatch({
      type: REVIEW_DELETE_FAIL,
      payload: message,
    });
  }
};

export const getRevMedDashboard =
  (admissionId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: REV_MED_DASHBOARD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/reviews/revMedDashboard/${admissionId}`,
        config
      );

      dispatch({
        type: REV_MED_DASHBOARD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      // if (message === 'Not authorized, token failed') {
      //   dispatch(logout())
      // }
      dispatch({
        type: REV_MED_DASHBOARD_FAIL,
        payload: message,
      });
    }
  };

//Review Queries

export const listAdmissionReviews = (props) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMISSION_REVIEWS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        team: props.team,
      },
    };

    const { data } = await axios.get(
      `/api/reviews/listAdmissionReviews/${props.admissionId}`,
      config
    );
    dispatch({
      type: ADMISSION_REVIEWS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMISSION_REVIEWS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listReviewsPerDay = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LIST_REVEWS_PER_DAY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/reviews/countsReviewPerDay?team=${filters.teamId || ""}&startDate=${
        filters.startDate || ""
      }&endDate=${filters.endDate || ""}`,
      config
    );

    dispatch({
      type: LIST_REVEWS_PER_DAY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: LIST_REVEWS_PER_DAY_FAIL,
      payload: message,
    });
  }
};

export const listSearchReviews = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REVIEW_SEARCH_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        team: filters.teamId,
      },
    };

    const { data } = await axios.get(
      `/api/reviews?team=${filters.teamId || ""}&startDate=${
        filters.startDate || ""
      }&endDate=${filters.endDate || ""}`,
      config
    );
    dispatch({
      type: REVIEW_SEARCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REVIEW_SEARCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listSearchProgressReviews =
  (filters) => async (dispatch, getState) => {
    try {
      dispatch({
        type: REVIEW_SEARCH_PROGRESS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/reviews/searchProgress?team=${filters.teamId || ""}&startDate=${
          filters.startDate || ""
        }&endDate=${filters.endDate || ""}`,
        config
      );
      dispatch({
        type: REVIEW_SEARCH_PROGRESS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REVIEW_SEARCH_PROGRESS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const reviewsPerTagAction = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REVIEWS_PER_TAG_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/reviews/listReviewsPerTag?team=${filters.teamId || ""}&startDate=${
        filters.startDate || ""
      }&endDate=${filters.endDate || ""}`,
      config
    );
    dispatch({
      type: REVIEWS_PER_TAG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REVIEWS_PER_TAG_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getErrTypeDistribution =
  (filters) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ERR_TYPE_DIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/reviews/errsByTypeDistribution?team=${
          filters.teamId || ""
        }&startDate=${filters.startDate || ""}&endDate=${
          filters.endDate || ""
        }`,
        config
      );
      dispatch({
        type: ERR_TYPE_DIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ERR_TYPE_DIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
