import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PatientRegister from "../components/PatientRegister";
import PatientList from "../components/PatientList";
import FormContainer from "../components/FormContainer";
import Skeleton from "react-loading-skeleton";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import { Button } from "react-bootstrap";

const PatientListScreen = () => {
  const params = useParams();
  const teamId = params.id;

  const [showInpatient, setShowInpatient] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [patientListVehicle, setPatientListVehicle] = useState([]);

  const teamDetails = useSelector((state) => state.teamDetails);
  const { team } = teamDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const patientListStore = useSelector((state) => state.patientListStore);
  const { loading } = patientListStore;

  let editPermission =
    team && userInfo && team.members.find((m) => m.user === userInfo.email)
      ? team.members.find((m) => m.user === userInfo.email).canEdit
      : false;

  const navigate = useNavigate();
  const location = useLocation();

  const nextPageNumber = () => {
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    if (!userInfo) {
      navigate(`/?returnFrom=${location.pathname}`);
    }
  }, [userInfo, navigate]);
  return (
    <FormContainer>
      {!team ? (
        <Skeleton height="50px" className="mb-5" />
      ) : (
        <BreadcrumbComponent
          titles={[
            { idx: "1", label: "Home", path: `/`, active: false },
            {
              idx: "2",
              label: `${team.name}`,
              path: `/team/${team._id}`,
              active: false,
            },
            {
              idx: "3",
              label: `Patients`,
              path: `/patientlist/${team._id}`,
              active: true,
            },
          ]}
        />
      )}

      <PatientRegister
        teamId={teamId}
        showInpatient={showInpatient}
        pageNumber={pageNumber}
        editPermission={editPermission}
        patientListVehicle={patientListVehicle}
        setPatientListVehicle={setPatientListVehicle}
      />

      <PatientList
        patientListVehicle={patientListVehicle}
        setPatientListVehicle={setPatientListVehicle}
        pageNumber={pageNumber}
        teamId={teamId}
        showInpatient={showInpatient}
        setShowInpatient={setShowInpatient}
      />

      {!loading && patientListVehicle && (
        <Button className="btn btn-block" onClick={() => nextPageNumber()}>
          Load more
        </Button>
      )}
    </FormContainer>
  );
};

export default PatientListScreen;
