import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  REVIEW_CREATE_RESET,
  REVIEW_DELETE_RESET,
} from "../constants/reviewConstants";
import Loader from "./Loader";
import Message from "./Message";
import { Accordion, Button, Form } from "react-bootstrap";
import { Howl } from "howler";
import ReviewAccordion from "./ReviewAccordion";

const ReviewList = ({
  patientId,
  admission,
  birthdate,
  labeledReview,
  setLabeledReview,
  synchedReviews,
  setSynchedReviews,
}) => {
  var sound = new Howl({
    src: `https://www.clinicbook.org/success.mp3`,
  });

  const [message, setMessage] = useState("");
  const [expandAllRev, setExpandAllRev] = useState();

  const reviewsByAdmissionStore = useSelector(
    (state) => state.reviewsByAdmissionStore
  );
  const { loading: loadAdmRev, reviews, error } = reviewsByAdmissionStore;

  const reviewAddStore = useSelector((state) => state.reviewAddStore);
  const { newReview } = reviewAddStore;

  const reviewDeleteStore = useSelector((state) => state.reviewDeleteStore);
  const { deletedReview } = reviewDeleteStore;

  const dispatch = useDispatch();

  const handleExpand = () => {
    console.log("kk");
    if (!expandAllRev) {
      setExpandAllRev("expand");
    } else {
      setExpandAllRev();
    }
  };

  useEffect(() => {
    if (synchedReviews && labeledReview) {
      const clientHeight = document.getElementById(labeledReview);
      if (clientHeight) {
        clientHeight.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }

    if (!synchedReviews) {
      if (reviews) {
        setSynchedReviews(reviews);
      }
    } else {
      if (newReview) {
        setLabeledReview(newReview._id);
        let list = [...synchedReviews, newReview];
        list = list.sort((a, b) => (a.reviewDate > b.reviewDate ? 1 : -1));
        setSynchedReviews(list);
        sound.play();
        dispatch({ type: REVIEW_CREATE_RESET });
      }

      if (deletedReview) {
        let list = synchedReviews.filter((r) => {
          return r._id !== deletedReview._id;
        });
        setSynchedReviews(list);
        sound.play();
        dispatch({ type: REVIEW_DELETE_RESET });
      }
    }

    if (error) {
      setMessage(error);
    }
  }, [synchedReviews, reviews, newReview, deletedReview, labeledReview, error]);
  return (
    <>
      {message && (
        <Message children={message} variant="danger" setMessgae={setMessage} />
      )}
      {loadAdmRev ? (
        <Loader />
      ) : (
        <>
          <Form.Check
            className="my-3"
            type="switch"
            id="expand"
            onChange={() => handleExpand()}
            label="Expand All"
          />
          {synchedReviews && (
            <>
              <Accordion
                className="py-2"
                activeKey={expandAllRev && expandAllRev}
              >
                {synchedReviews.map((r) => (
                  <div key={r._id} className="my-2">
                    <ReviewAccordion
                      review={r}
                      admission={admission}
                      labeledReview={labeledReview}
                      patientId={patientId}
                      expandAllRev={expandAllRev}
                    />
                  </div>
                ))}
              </Accordion>
              <div>
                <Button
                  onClick={() =>
                    window.open(
                      `https://chat.openai.com/?q=A Case with birthdate: ${birthdate.substring(
                        0,
                        10
                      )} with ${admission.admissionDetails.replace(
                        /<\/?p>|&nbsp;|[#]|[$]|[&]/g,
                        ""
                      )} and ${synchedReviews.map(
                        (r) =>
                          `.. in day ${r.reviewDate.substring(
                            0,
                            10
                          )} patient was ${r.clinicalNote.replace(
                            /<\/?p>|&nbsp;|[#]|[$]|[&]/g,
                            ""
                          )} and on medications: ${r.drugErrs.map(
                            (d) => `${d.errDrugLabel},`
                          )}`
                      )} summarize the case, find in depth any drug drug interaction, match patient current data with medication adverse drug reaction and told me which may be developed by medications, get any recommended drug therapy monitoring and any recent guideline based recommendetions`,
                      "blank"
                    )
                  }
                >
                  Ask ChatGPT
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ReviewList;
