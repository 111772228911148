import {
  TAG_LIST_FAIL,
  TAG_LIST_REQUEST,
  TAG_LIST_RESET,
  TAG_LIST_SUCCESS,
} from '../constants/tagConstants'

export const tagListReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case TAG_LIST_REQUEST:
      return { loading: true }
    case TAG_LIST_SUCCESS:
      return { loading: false, tagList: action.payload }
    case TAG_LIST_FAIL:
      return { loading: false, error: action.payload }
    case TAG_LIST_RESET:
      return { noRequest: true }
    default:
      return state
  }
}
