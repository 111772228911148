import axios from 'axios'
import {
  TAG_LIST_FAIL,
  TAG_LIST_REQUEST,
  TAG_LIST_SUCCESS,
} from '../constants/tagConstants'

export const listTags = () => async (dispatch) => {
  try {
    dispatch({
      type: TAG_LIST_REQUEST,
    })

    const { data } = await axios.get(`/api/tags`)
    dispatch({
      type: TAG_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TAG_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
