import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  FormLabel,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  listSearchReviews,
  listReviewsPerDay,
  listSearchProgressReviews,
  reviewsPerTagAction,
} from "../actions/reviewActions";
import FormContainer from "../components/FormContainer";
import { getTeamDetails } from "../actions/teamActions";
import Loader from "../components/Loader";
import {
  ERR_TYPE_DIST_RESET,
  LIST_REVEWS_PER_DAY_RESET,
  REVIEWS_PER_TAG_RESET,
  REVIEW_SEARCH_PROGRESS_RESET,
  REVIEW_SEARCH_RESET,
} from "../constants/reviewConstants";
import { listRecurrentAdmissions } from "../actions/admissionActions";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "../components/ReviewSearchPrint";
import { ComponentToPrintType } from "../components/ErrTypeDistribPrint";
import { getErrTypeDistribution } from "../actions/reviewActions";
import reportTypes from "../data/reportTypes";
import ReviewSearchDisplay from "../components/ReviewSearchDisplay";
import ReviewSearchPerDay from "../components/ReviewsSearchPerDay";
import { ADMISSION_RECURRENT_RESET } from "../constants/admissionConstants";
import Skeleton from "react-loading-skeleton";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import ProgBar from "../components/ProgBar";
import { CSVLink } from "react-csv";

const ReviewSearchScreen = () => {
  const params = useParams();
  const teamId = params.id;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hideDates, setHideDates] = useState(false);
  const [reportType, setReportType] = useState("");

  const reviewSearchProgressStore = useSelector(
    (state) => state.reviewSearchProgressStore
  );
  const { noRequest, totalDocs } = reviewSearchProgressStore;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { team } = teamDetails;

  const reviewSearchStore = useSelector((state) => state.reviewSearchStore);
  const {
    loading: loadingReviews,
    reviews,
    csv,
    noRequest: noSearchRequest,
    error: searchErr,
  } = reviewSearchStore;

  const reviewsByErrTypeStore = useSelector(
    (state) => state.reviewsByErrTypeStore
  );
  const { loading: loadingErrDist, errTypeDist } = reviewsByErrTypeStore;

  const reviewsPerDayStore = useSelector((state) => state.reviewsPerDayStore);
  const { reviewsPerDay } = reviewsPerDayStore;

  const admRecurrentStore = useSelector((state) => state.admRecurrentStore);
  const { loading: loadingRecurrentAdm, result } = admRecurrentStore;

  const reviewsPerTagStore = useSelector((state) => state.reviewsPerTagStore);
  const {
    noRequest: noReviewsPerTagRequest,
    loading: loadingReviewsPerTag,
    reviewsPerTag,
  } = reviewsPerTagStore;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef();

  const handleClear = () => {
    dispatch({ type: REVIEW_SEARCH_RESET });
    dispatch({ type: ERR_TYPE_DIST_RESET });
    dispatch({ type: LIST_REVEWS_PER_DAY_RESET });
    dispatch({ type: REVIEW_SEARCH_PROGRESS_RESET });
    dispatch({ type: REVIEWS_PER_TAG_RESET });
    setStartDate("");
    setEndDate("");
    setReportType("");
  };

  useEffect(() => {
    if (!userInfo) {
      navigate(`/`);
    } else {
      if (!team || team._id !== teamId) {
        dispatch(getTeamDetails(teamId));
        dispatch({ type: REVIEW_SEARCH_RESET });
      }
      if (!startDate && !endDate) {
        dispatch({ type: REVIEW_SEARCH_RESET });
        dispatch({ type: ERR_TYPE_DIST_RESET });
        dispatch({ type: ADMISSION_RECURRENT_RESET });
        dispatch({ type: REVIEWS_PER_TAG_RESET });
      }

      if (startDate && endDate && teamId && !reviews && reportType === "1") {
        dispatch({ type: ERR_TYPE_DIST_RESET });
        if (noSearchRequest) {
          dispatch(listSearchReviews({ startDate, endDate, teamId }));
        }
        if (noRequest) {
          dispatch(listSearchProgressReviews({ startDate, endDate, teamId }));
        }
      }

      if (
        startDate &&
        endDate &&
        teamId &&
        !errTypeDist &&
        reportType === "2"
      ) {
        dispatch({ type: REVIEW_SEARCH_RESET });
        dispatch(getErrTypeDistribution({ startDate, endDate, teamId }));
      }

      if (startDate && endDate && teamId && reportType === "3") {
        dispatch(listReviewsPerDay({ startDate, endDate, teamId }));
      }
      if (reportType === "4") {
        setHideDates(true);
        dispatch(listRecurrentAdmissions(teamId));
      }
      if (
        startDate &&
        endDate &&
        teamId &&
        noReviewsPerTagRequest &&
        !reviewsPerTag &&
        reportType === "5"
      ) {
        dispatch(
          reviewsPerTagAction({
            startDate,
            endDate,
            teamId,
          })
        );
        if (noRequest) {
          dispatch(listSearchProgressReviews({ startDate, endDate, teamId }));
        }
      }
    }
  }, [
    reviews,
    team,
    teamId,
    startDate,
    endDate,
    reportType,
    userInfo,
    totalDocs,
    noSearchRequest,
    noRequest,
    navigate,
  ]);

  return (
    <div>
      <FormContainer>
        {!team ? (
          <Skeleton height="50px" className="mb-5" />
        ) : (
          <BreadcrumbComponent
            titles={[
              { idx: "1", label: "Home", path: `/`, active: false },
              {
                idx: "2",
                label: `${team.name}`,
                path: `/team/${team._id}`,
                active: false,
              },
              {
                idx: "3",
                label: `Search`,
                path: `/reviewsearch/${team._id}`,
                active: true,
              },
            ]}
          />
        )}
        <Form className="row">
          <Form.Group controlId="reportType" className="col-sm-12">
            <FormLabel>Report Type</FormLabel>
            <select
              className="custom-select"
              type="name"
              disabled={reviews || errTypeDist || reviewsPerDay ? true : false}
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
            >
              {reportTypes.map((type) => (
                <option
                  key={type._id}
                  value={type._id}
                  disabled={type.disabled ? true : false}
                >
                  {type.label}
                </option>
              ))}
            </select>
          </Form.Group>

          {!hideDates && (
            <Form.Group controlId="startDate" className="col-sm-6">
              <Form.Label>
                <h2>Start Date</h2>
              </Form.Label>
              <Form.Control
                type="date"
                disabled={
                  reviews || errTypeDist || reviewsPerDay ? true : false
                }
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              ></Form.Control>
            </Form.Group>
          )}

          {!hideDates && (
            <Form.Group controlId="endDate" className="col-sm-6">
              <Form.Label>
                <h2>End Date</h2>
              </Form.Label>
              <Form.Control
                type="date"
                disabled={
                  reviews || errTypeDist || reviewsPerDay ? true : false
                }
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              ></Form.Control>
            </Form.Group>
          )}
        </Form>
        <div className="container mt-3">
          <div className="d-flex justify-content-between">
            <Button
              disabled={reviews || errTypeDist || reviewsPerDay ? false : true}
              onClick={() => handleClear()}
              className="col-sm-4 mr-1"
            >
              Clear Form
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button
                  className="col-sm-4 ml-1"
                  disabled={reviews || errTypeDist ? false : true}
                >
                  Print Result
                </Button>
              )}
              content={() => componentRef.current}
              documentTitle={`${team && team.name} ${startDate} to ${endDate}`}
            />
          </div>
        </div>
        {csv && (
          <Row className="mt-5 justify-content-center">
            <Col>
              {csv && team.admin === userInfo.email && (
                <CSVLink data={csv}>reviews csv</CSVLink>
              )}
            </Col>
          </Row>
        )}

        {loadingReviews && totalDocs && (
          <ProgBar unitDuration={totalDocs / 250} />
        )}

        {loadingReviewsPerTag && totalDocs && (
          <ProgBar unitDuration={totalDocs / 1000} />
        )}

        {searchErr && <Alert children={searchErr} variant="danger" />}
        {loadingReviews && <Loader />}
        {loadingErrDist && <Loader />}
        {loadingRecurrentAdm && <Loader />}

        {loadingReviewsPerTag && <Loader />}

        {reviewsPerTag &&
          reviewsPerTag.map((t, order = 0) => (
            <div key={t._id}>
              <h3>{`${order + 1}-${t.tag}: ${t.count} Err`}</h3>

              <div>
                {t.response.map((r, idx = 0) => (
                  <span key={idx} className="justify-content-center ">
                    {` ${r.type}: ${r.count}, `}
                  </span>
                ))}
              </div>
            </div>
          ))}
        {reviews && (
          <div style={{ display: "none" }}>
            <ComponentToPrint
              ref={componentRef}
              reviews={reviews}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        )}
        {reviews && (
          <ReviewSearchDisplay
            reviews={reviews}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        {errTypeDist && (
          <div style={{ display: "none" }}>
            <ComponentToPrintType
              ref={componentRef}
              team={team}
              errTypeDist={errTypeDist}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        )}
        <ReviewSearchPerDay />
        {result && (
          <Table>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">NO. of Admissions</th>
                <th scope="col">Dates</th>
                <th scope="col">Link</th>
              </tr>
            </thead>
            {result &&
              result.map((r, i = 0) => (
                <tbody>
                  <tr>
                    <td>{`${i + 1}- ${r.firstName} ${r.middleName} ${
                      r.lastName
                    }`}</td>
                    <td>{r.admissionCount}</td>
                    <td>
                      {r.admDetails.map((d) => (
                        <Row key={d._id}>
                          <div>{d.admissionDate.substring(0, 10)}</div>
                        </Row>
                      ))}
                    </td>
                    <td
                      onClick={() => {
                        window.open(
                          `https://www.clinicbook.org/patient/${r._id}`,
                          "_blank"
                        );
                      }}
                    >
                      <i className="fas fa-external-link-alt"></i>
                    </td>
                  </tr>
                </tbody>
              ))}
          </Table>
        )}
      </FormContainer>
    </div>
  );
};

export default ReviewSearchScreen;
