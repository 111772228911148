import React from "react";
import { differenceInDays, format } from "date-fns";
import { Accordion, Card, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

const PhysicNoteAccordion = ({
  noteData,
  admission,
  labeledNote,
  setShowEdit,
  expandAll,
}) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <div>
      <Accordion.Toggle
        style={{
          backgroundColor:
            noteData._id && noteData._id.toString() === labeledNote
              ? "#14A44D"
              : "#00abff",
        }}
        id={noteData._id}
        eventKey={expandAll ? expandAll : noteData._id}
        as={Card.Header}
        variant="link"
      >
        <div className="row text-white">
          <div className="col col-sm-5">
            {noteData.noteDate &&
              admission &&
              ` D${differenceInDays(
                new Date(noteData.noteDate),
                new Date(admission.admissionDate)
              )}: ${format(new Date(noteData.noteDate), "eeee")}
        ${noteData.noteDate.substring(0, 10)}`}
          </div>
          <div className="col col-sm-5">
            <div className="row justify-content-end pr-5">
              <span>Edited by: {noteData.userName}</span>
            </div>
          </div>
          <div className="col col-sm-2">
            {" "}
            <i className="fas fa-angle-double-down"></i>
          </div>
        </div>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={expandAll ? expandAll : noteData._id}>
        <div className="container">
          <div>
            {" "}
            Clinical Notes:{" "}
            <div
              className="text-primary"
              dangerouslySetInnerHTML={{
                __html: `${noteData.note}`,
              }}
            />
          </div>
          <hr style={{ backgroundColor: "blue" }} />

          <Row>
            <Col>
              <Row className="row justify-content-end">
                {userInfo && userInfo._id === noteData.userId ? (
                  <div
                    onClick={() => setShowEdit(true)}
                    className="btn btn-outline-success"
                  >
                    Edit
                  </div>
                ) : (
                  <div
                    className="mr-3"
                    onClick={() =>
                      window.open(
                        `https://wa.me/+2${noteData.userPhone}?text=I'm inquiring about your review at https://www.clinicbook.org/admissions/${admission._id}?review=${noteData._id}`
                      )
                    }
                  >
                    <i
                      className="fab fa-whatsapp"
                      style={{
                        fontFamily: "Font Awesome 5 Brands",
                        fontSize: "36px",
                        color: "green",
                      }}
                      aria-hidden="true"
                    ></i>
                  </div>
                )}
              </Row>
            </Col>
          </Row>
          <hr style={{ backgroundColor: "blue" }} />
        </div>
      </Accordion.Collapse>
    </div>
  );
};

export default PhysicNoteAccordion;
