import React from 'react'
import getPageMargins from './getPageMargins'

export const ComponentToPrintType = React.forwardRef(
  ({ errTypeDist, startDate, endDate, team }, ref) => {
    return (
      <div
        className="bg-white text-dark text-bold"
        style={{ fontFamily: 'Centaur' }}
        ref={ref}>
        <div>
          <style>{getPageMargins()}</style>

          <div>
            <h4>Clinical Pharmacy Team</h4>
          </div>
          <div className="row text-center">
            <div className="col">
              <h3>
                Err Type Distribution Report <br />
                {`From ${startDate} To ${endDate}`}
                <hr style={{ backgroundColor: 'black', size: '30px' }} />
              </h3>
            </div>
          </div>

          {errTypeDist.map((t) => (
            <ul key={t}>
              <li>
                <h3 className="text-bold">{`${t.type}: ${t.count} errors`}</h3>
              </li>
            </ul>
          ))}
        </div>
      </div>
    )
  }
)
