import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Button, Form, Card, Row } from "react-bootstrap";

import Loader from "./Loader";
import Message from "./Message";
import TextEditor from "./TextEditor";
import { createPhysicNote } from "../actions/physNoteAction";

const PhysicNoteForm = ({ admissionId, patientId, teamId, errTypeList }) => {
  const [noteDate, setNoteDate] = useState(new Date());
  const [note, setNote] = useState("");

  const [message, setMessage] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const physicNoteCreateStore = useSelector(
    (state) => state.physicNoteCreateStore
  );
  const { noRequest, loading } = physicNoteCreateStore;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (noRequest) {
      dispatch(
        createPhysicNote({
          admissionId,
          userId: userInfo._id,
          patientId,
          teamId,
          noteDate,
          note,
        })
      );
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <Accordion className="mt-3" defaultActiveKey="0">
        <Accordion.Toggle
          style={{ backgroundColor: "#54B4D3", color: "white" }}
          eventKey="0"
          as={Card.Header}
          variant="link"
        >
          <Row className="justify-content-center">
            <i className="fas fa-angle-double-down pt-1 pr-2"></i>CREATE NEW
            PHYSICIAN NOTE
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Form>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Form.Group controlId="reviewDate">
                  <Form.Label>
                    <h2>Note Date</h2>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={noteDate}
                    onChange={(e) => setNoteDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="clinicalNote">
                  <Form.Label>
                    <h2>Clinical Notes</h2>
                  </Form.Label>
                  <div className="text-dark">
                    {" "}
                    <TextEditor text={note} setText={setNote} />
                  </div>
                </Form.Group>

                <div className="row justify-content-end">
                  <Button
                    onClick={(e) => submitHandler(e)}
                    className="btn-primary"
                  >
                    Add Note
                  </Button>
                </div>
              </>
            )}
          </Form>
        </Accordion.Collapse>
      </Accordion>
      {message && (
        <Message children={message} variant="danger" setMessgae={setMessage} />
      )}
    </>
  );
};

export default PhysicNoteForm;
