import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navbar, Nav, Container, NavDropdown, Image } from "react-bootstrap";

import { logout } from "../actions/userActions";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <>
      <header>
        <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg">
          <Container>
            <div
              onClick={() => {
                navigate("/");
              }}
            >
              <Nav.Link>
                <Image src="/images/logo.png" alt="Ayman Hamouda" fluid />
              </Nav.Link>
            </div>
            <div
              onClick={() => {
                navigate("/");
              }}
            >
              <Navbar.Brand>
                <span className="lead" style={{ color: "#00abff" }}>
                  ClinicBook.Org
                </span>
              </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto text-right">
                <Nav.Link
                  eventKey={2}
                  className="mx-1 text-white"
                  onClick={() => navigate("/")}
                >
                  Home
                </Nav.Link>

                <Nav.Link
                  eventKey={2}
                  className="mx-1 text-white"
                  onClick={() => navigate("/clinical-pharmacy-courses")}
                >
                  Courses
                </Nav.Link>

                {userInfo ? (
                  <NavDropdown
                    active
                    title={`${userInfo.firstName} ${userInfo.lastName}`}
                    id="username"
                  >
                    <NavDropdown.Item
                      className="text-right"
                      eventKey={2}
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      Profile
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      className="text-right"
                      eventKey={2}
                      onClick={logoutHandler}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <Nav.Link
                    eventKey={2}
                    className="mx-1 text-white"
                    onClick={() => navigate("/register")}
                  >
                    Create Account
                  </Nav.Link>
                )}

                <Nav.Link
                  eventKey={2}
                  className="mx-1 text-white"
                  onClick={() => navigate("/about")}
                >
                  About
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
