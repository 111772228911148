import {
  ADMISSION_CREATE_REQUEST,
  ADMISSION_CREATE_SUCCESS,
  ADMISSION_CREATE_FAIL,
  ADMISSION_LIST_SUCCESS,
  ADMISSION_LIST_FAIL,
  ADMISSION_LIST_REQUEST,
  ADMISSION_LIST_RESET,
  ADMISSION_UPDATE_REQUEST,
  ADMISSION_UPDATE_SUCCESS,
  ADMISSION_UPDATE_FAIL,
  ADMISSION_DETAILS_REQUEST,
  ADMISSION_DETAILS_SUCCESS,
  ADMISSION_DETAILS_FAIL,
  ADMISSION_DETAILS_RESET,
  ADMISSION_CREATE_RESET,
  ADMISSION_UPDATE_RESET,
  ADMISSION_DELETE_REQUEST,
  ADMISSION_DELETE_SUCCESS,
  ADMISSION_DELETE_FAIL,
  ADMISSION_DELETE_RESET,
  ADMISSION_RECURRENT_REQUEST,
  ADMISSION_RECURRENT_SUCCESS,
  ADMISSION_RECURRENT_FAIL,
  ADMISSION_RECURRENT_RESET,
  UPDATE_ADM_MED_REQUEST,
  UPDATE_ADM_MED_SUCCESS,
  UPDATE_ADM_MED_FAIL,
  UPDATE_ADM_MED_RESET,
  NEW_ADM_MED_REQUEST,
  NEW_ADM_MED_SUCCESS,
  NEW_ADM_MED_FAIL,
  NEW_ADM_MED_RESET,
  DELETE_ADM_MED_REQUEST,
  DELETE_ADM_MED_SUCCESS,
  DELETE_ADM_MED_FAIL,
  DELETE_ADM_MED_RESET,
  SORT_ADM_MED_REQUEST,
  SORT_ADM_MED_SUCCESS,
  SORT_ADM_MED_FAIL,
  SORT_ADM_MED_RESET,
} from "../constants/admissionConstants";

export const admissionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMISSION_CREATE_REQUEST:
      return { loading: true };
    case ADMISSION_CREATE_SUCCESS:
      return { loading: false, newAdmission: action.payload };
    case ADMISSION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMISSION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const admissionDetailsReducer = (
  state = { admission: "", noRequest: true },
  action
) => {
  switch (action.type) {
    case ADMISSION_DETAILS_REQUEST:
      return { loading: true };
    case ADMISSION_DETAILS_SUCCESS:
      return { loading: false, admission: action.payload };
    case ADMISSION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ADMISSION_DETAILS_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const admissionListReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMISSION_LIST_REQUEST:
      return { loading: true };
    case ADMISSION_LIST_SUCCESS:
      return { loading: false, admissions: action.payload };
    case ADMISSION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ADMISSION_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const admissionRecurrentReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMISSION_RECURRENT_REQUEST:
      return { loading: true };
    case ADMISSION_RECURRENT_SUCCESS:
      return { loading: false, result: action.payload };
    case ADMISSION_RECURRENT_FAIL:
      return { loading: false, error: action.payload };
    case ADMISSION_RECURRENT_RESET:
      return {};
    default:
      return state;
  }
};

export const admissionUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMISSION_UPDATE_REQUEST:
      return { loading: true };
    case ADMISSION_UPDATE_SUCCESS:
      return { loading: false, updatedAdmission: action.payload };
    case ADMISSION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMISSION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const admMedUpdateReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case UPDATE_ADM_MED_REQUEST:
      return { loading: true };
    case UPDATE_ADM_MED_SUCCESS:
      return { loading: false, admissionMedications: action.payload };
    case UPDATE_ADM_MED_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_ADM_MED_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const admMedSortReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case SORT_ADM_MED_REQUEST:
      return { loading: true };
    case SORT_ADM_MED_SUCCESS:
      return { loading: false, message: action.payload };
    case SORT_ADM_MED_FAIL:
      return { loading: false, error: action.payload };
    case SORT_ADM_MED_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const newAdmMedReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case NEW_ADM_MED_REQUEST:
      return { loading: true };
    case NEW_ADM_MED_SUCCESS:
      return { loading: false, newMedication: action.payload };
    case NEW_ADM_MED_FAIL:
      return { loading: false, error: action.payload };
    case NEW_ADM_MED_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const deletedAdmMedReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case DELETE_ADM_MED_REQUEST:
      return { loading: true };
    case DELETE_ADM_MED_SUCCESS:
      return { loading: false, message: action.payload };
    case DELETE_ADM_MED_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_ADM_MED_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const admissionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMISSION_DELETE_REQUEST:
      return { loading: true };
    case ADMISSION_DELETE_SUCCESS:
      return { loading: false, deletedAdmission: action.payload };
    case ADMISSION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMISSION_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
