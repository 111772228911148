export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const SPONSORS_REQUEST = "SPONSORS_REQUEST";
export const SPONSORS_SUCCESS = "SPONSORS_SUCCESS";
export const SPONSORS_FAIL = "SPONSORS_FAIL";
export const SPONSORS_RESET = "SPONSORS_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_CV_REQUEST = "USER_CV_REQUEST";
export const USER_CV_SUCCESS = "USER_CV_SUCCESS";
export const USER_CV_FAIL = "USER_CV_FAIL";
export const USER_CV_RESET = "USER_CV_RESET";

export const RECOMMEND_USER_REQUEST = "RECOMMEND_USER_REQUEST";
export const RECOMMEND_USER_SUCCESS = "RECOMMEND_USER_SUCCESS";
export const RECOMMEND_USER_FAIL = "RECOMMEND_USER_FAIL";
export const RECOMMEND_USER_RESET = "RECOMMEND_USER_RESET";

export const USER_UPDATE_DETAILS_REQUEST = "USER_UPDATE_DETAILS_REQUEST";
export const USER_UPDATE_DETAILS_SUCCESS = "USER_UPDATE_DETAILS_SUCCESS";
export const USER_UPDATE_DETAILS_FAIL = "USER_UPDATE_DETAILS_FAIL";
export const USER_UPDATE_DETAILS_RESET = "USER_DETAILS_RESET";
