export const TRADE_LABELS_REQUEST = 'TRADE_LABELS_REQUEST'
export const TRADE_LABELS_SUCCESS = 'TRADE_LABELS_SUCCESS'
export const TRADE_LABELS_FAIL = 'TRADE_LABELS_FAIL'
export const TRADE_LABELS_RESET = 'TRADE_LABELS_RESET'

export const ERR_TEMPS_REQUEST = 'ERR_TEMPS_REQUEST'
export const ERR_TEMPS_SUCCESS = 'ERR_TEMPS_SUCCESS'
export const ERR_TEMPS_FAIL = 'ERR_TEMPS_FAIL'
export const ERR_TEMPS_RESET = 'ERR_TEMPS_RESET'

export const NEW_DRUG_REQUEST = 'NEW_DRUG_REQUEST'
export const NEW_DRUG_SUCCESS = 'NEW_DRUG_SUCCESS'
export const NEW_DRUG_FAIL = 'NEW_DRUG_FAIL'
export const NEW_DRUG_RESET = 'NEW_DRUG_RESET'

export const ROOT_BY_TRADEID_REQUEST = 'ROOT_BY_TRADEID_REQUEST'
export const ROOT_BY_TRADEID_SUCCESS = 'ROOT_BY_TRADEID_SUCCESS'
export const ROOT_BY_TRADEID_FAIL = 'ROOT_BY_TRADEID_FAIL'
export const ROOT_BY_TRADEID_RESET = 'ROOT_BY_TRADEID_RESET'

export const UPDATE_ROOT_REQUEST = 'UPDATE_ROOT_REQUEST'
export const UPDATE_ROOT_SUCCESS = 'UPDATE_ROOT_SUCCESS'
export const UPDATE_ROOT_FAIL = 'UPDATE_ROOT_FAIL'
export const UPDATE_ROOT_RESET = 'UPDATE_ROOT_RESET'

export const LIST_DRUG_ROOTS_REQUEST = 'LIST_DRUG_ROOTS_REQUEST'
export const LIST_DRUG_ROOTS_SUCCESS = 'LIST_DRUG_ROOTS_SUCCESS'
export const LIST_DRUG_ROOTS_FAIL = 'LIST_DRUG_ROOTS_FAIL'
export const LIST_DRUG_ROOTS_RESET = 'LIST_DRUG_ROOTS_RESET'
