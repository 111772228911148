import React, { useEffect, useState } from "react";
import resTypes from "../data/resTypes";
import severityScale from "../data/severityScale";
import Rating from "react-rating";
import {
  Button,
  Col,
  Row,
  Form,
  ButtonToolbar,
  FormGroup,
  Container,
  FormCheck,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  delRevMed,
  newRevMed,
  rateErr,
  updateRevMed,
} from "../actions/reviewActions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Typeahead } from "react-bootstrap-typeahead";
import { listTradeLabels } from "../actions/drugActions";
import Loader from "./Loader";
import ReviewTemplateModal from "./ReviewTemplateModal";
import { notify } from "./notify";
import {
  REV_MED_ADD_RESET,
  REV_MED_DEL_RESET,
  REV_MED_UPDATE_RESET,
} from "../constants/reviewConstants";

const RevError = ({ drugErr, review, setDrugErrs, drugErrs }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const [selectedDgErr, setSelectedDgErr] = useState();

  const errTypes = useSelector((state) => state.errTypes);
  const { errTypeList } = errTypes;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { team } = teamDetails;

  const revMedUpdateStore = useSelector((state) => state.revMedUpdateStore);
  const { updatedMedId } = revMedUpdateStore;

  const revMedAddStore = useSelector((state) => state.revMedAddStore);
  const { newMedId } = revMedAddStore;

  const revMedDelStore = useSelector((state) => state.revMedDelStore);
  const { deletedMedId } = revMedDelStore;

  const tradeLabelsStore = useSelector((state) => state.tradeLabelsStore);
  const { noRequest } = tradeLabelsStore;

  const handleDrug = (e, dgErr) => {
    if (e.length !== 0) {
      dgErr.errDrug = e["0"]._id;
      dgErr.errDrugLabel = e["0"].label;
    }
  };

  const handleErrType = (e, dgErr) => {
    dgErr.errType = e.target.value;
    dgErr.errTypeLabel = errTypeList.find(
      (et) => et._id.toString() === e.target.value.toString()
    ).label;
    if (e.target.value === "659edafa7198ed5cc6af99bb") {
      dgErr.errSeverity = "";
      dgErr.errRes = "";
    }
  };

  const modalTemplates = (e) => {
    setShowTemplates(!showTemplates);
    setSelectedDgErr(e);
  };

  const handleErrResp = (e, dgErr) => {
    dgErr.errRes = e.target.value;
    dgErr.errResLabel = e.target.label;
  };

  const handleErrSeverity = (e, dgErr) => {
    dgErr.errSeverity = e.target.value;
    dgErr.errSeverityLabel = e.target.label;
  };
  const handleErrNote = (data, dgErr) => {
    dgErr.errNote = data;
  };
  const dispatch = useDispatch();

  if (!localStorage.tradeLabels && noRequest) {
    dispatch(listTradeLabels());
  }

  let tradeLabels =
    localStorage.tradeLabels && JSON.parse(localStorage.tradeLabels);

  const handleRating = (r, rating, drugErr) => {
    dispatch(
      rateErr({
        reviewId: r._id,
        errId: drugErr._id,
        rating,
      })
    );
  };

  const deleteErrHandler = (drugErr) => {
    if (window.confirm("are you sure?")) {
      dispatch(
        delRevMed({
          reviewId: review._id,
          drugErr,
        })
      );
    }
  };

  const handleUpdate = () => {
    if (drugErr) {
      if (drugErr.idx) {
        dispatch(newRevMed({ reviewId: review._id, drugErr }));
      } else {
        dispatch(
          updateRevMed({
            reviewId: review._id,
            drugErr,
          })
        );
      }
    }

    setShowEdit(false);
  };
  useEffect(() => {
    if (drugErr.errDrug === "") {
      setShowEdit(true);
    }
    if (newMedId && drugErr && drugErr.idx) {
      if (newMedId.idx.toString() === drugErr.idx.toString()) {
        drugErr._id = newMedId._id;
        drugErr.idx = false;
        setChecked(true);
        notify({
          title: `Addition Saved`,
          message: `Medication Added Successfully👏👏`,
          type: "success",
          duration: 2500,
        });
        dispatch({ type: REV_MED_ADD_RESET });
      }
    }

    if (updatedMedId && drugErr && drugErr._id) {
      if (updatedMedId.toString() === drugErr._id.toString()) {
        setChecked(true);
        notify({
          title: `Edit Saved`,
          message: `Medication Updated Successfully👏👏`,
          type: "success",
          duration: 2500,
        });
        dispatch({ type: REV_MED_UPDATE_RESET });
      }
    }
    if (deletedMedId && drugErr) {
      if (deletedMedId.toString() === drugErr._id.toString()) {
        setDrugErrs(
          drugErrs.filter((d) => d._id.toString() !== drugErr._id.toString())
        );
        notify({
          title: `Del Success`,
          message: `${drugErr.errDrugLabel} deleted Successfully`,
          type: "danger",
          duration: 2500,
        });
        dispatch({ type: REV_MED_DEL_RESET });
      }
    }
  }, [updatedMedId, drugErr, newMedId, deletedMedId]);
  return (
    <div>
      <ReviewTemplateModal
        showTemplates={showTemplates}
        setShowTemplates={() => setShowTemplates()}
        selectedDgErr={selectedDgErr}
        setSelectedDgErr={setSelectedDgErr}
      />{" "}
      {showEdit ? (
        <>
          <div key={drugErr._id} className="row m-2 py-3 border border-primary">
            {!tradeLabels ? (
              <Loader />
            ) : (
              <Form.Group className="col-sm-6">
                <Form.Label>Drug Of Err</Form.Label>

                <Typeahead
                  placeholder="Enter Medication"
                  defaultInputValue={drugErr.errDrugLabel}
                  options={tradeLabels}
                  onChange={(e) => handleDrug(e, drugErr)}
                  id="errDrug"
                />
              </Form.Group>
            )}

            {errTypeList && (
              <FormGroup className="col-sm-6">
                <Form.Label>Type Of Err</Form.Label>
                <select
                  className="custom-select"
                  type="name"
                  defaultValue={drugErr.errType}
                  onChange={(e) => handleErrType(e, drugErr)}
                >
                  {errTypeList.map((t) => (
                    <option key={t._id} value={t._id}>
                      {t.label}
                    </option>
                  ))}
                </select>
              </FormGroup>
            )}

            <Form.Group className="col-sm-12">
              <Form.Label>Error Notes</Form.Label>
              <div className="text-dark">
                {" "}
                <CKEditor
                  editor={ClassicEditor}
                  data={drugErr.errNote}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleErrNote(data, drugErr);
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="col-sm-12">
              <Form.Label>Response</Form.Label>
              <select
                className="custom-select"
                type="name"
                defaultValue={drugErr.errRes}
                value={drugErr.errResLabel}
                onChange={(e) => handleErrResp(e, drugErr)}
              >
                {resTypes.map((r) => (
                  <option
                    key={r._id}
                    value={r._id}
                    disabled={r.disabled ? true : false}
                  >
                    {r.label}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Form.Group className="col-sm-12">
              <Form.Label>Error Severity</Form.Label>
              <select
                className="custom-select"
                type="name"
                defaultValue={drugErr.errSeverity}
                value={drugErr.errSeverityLabel}
                onChange={(e) => handleErrSeverity(e, drugErr)}
              >
                {severityScale.map((r) => (
                  <option
                    key={r._id}
                    value={r._id}
                    disabled={r.disabled ? true : false}
                  >
                    {r.label}
                  </option>
                ))}
              </select>
            </Form.Group>
            <ButtonToolbar className="container justify-content-center">
              <Button
                className="m-2"
                variant="warning"
                onClick={() => modalTemplates(drugErr)}
              >
                Templates
              </Button>

              <Button className="m-2" onClick={() => handleUpdate()}>
                Save
              </Button>
            </ButtonToolbar>
          </div>
        </>
      ) : (
        <Container
          style={{ background: checked && "#9FA6B2" }}
          key={drugErr._id}
          className="my-2"
        >
          <Row>
            <FormCheck
              type="checkbox"
              value={checked}
              onClick={() => setChecked(!checked)}
            />

            <Col className="col-sm-5">{drugErr.errDrugLabel}</Col>
            <Col className="col-sm-5">{drugErr.errTypeLabel}</Col>
          </Row>

          <Row>
            <span
              className="col text-primary"
              dangerouslySetInnerHTML={{
                __html: `${drugErr.errNote}`,
              }}
            />
          </Row>

          <Row>
            <div className="col">
              Response:
              <span className="text-primary">
                {`.  ${
                  resTypes.find((t) => t._id.toString() === drugErr.errRes)
                    .label
                }`}
              </span>
            </div>

            <div className="col">
              Rating:{" "}
              <Rating
                initialRating={drugErr.rating}
                readonly={
                  team && userInfo && team.admin === userInfo.email
                    ? false
                    : true
                }
                onChange={(rate) => handleRating(review, rate, drugErr)}
                emptySymbol={<img src="/images/star-.png" />}
                fullSymbol={<img src="/images/star.png" className="icon" />}
                fractions={2}
                stop={5}
              />
            </div>
          </Row>
          <Row className="mt-2">
            <Col>
              Severity:
              <span className="text-primary">
                {drugErr.errSeverity &&
                  severityScale.find(
                    (t) => t._id.toString() === drugErr.errSeverity.toString()
                  ) &&
                  `.  ${
                    severityScale.find(
                      (t) => t._id.toString() === drugErr.errSeverity.toString()
                    ).label
                  }`}
              </span>
            </Col>
          </Row>
          {review.userEmail === userInfo.email && (
            <Row className="my-3">
              <Col className="col-sm-6">
                <Row className="justify-content-start ml-2">
                  <Button
                    className="btn-danger "
                    onClick={() => deleteErrHandler(drugErr)}
                  >
                    Del
                  </Button>
                </Row>
              </Col>
              <Col className="col-sm-6">
                <Row className="justify-content-end mr-2">
                  <Button
                    className="btn-primary "
                    onClick={() => setShowEdit(true)}
                  >
                    Edit
                  </Button>
                </Row>
              </Col>
            </Row>
          )}
          <hr style={{ backgroundColor: "blue" }} />
        </Container>
      )}
    </div>
  );
};

export default RevError;
