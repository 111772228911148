import axios from "axios";
import {
  USER_ERR_TEMP_ADD_RESET,
  USER_ERR_TEMP_RESET,
} from "../constants/userErrTempConstants";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
  USER_UPDATE_DETAILS_REQUEST,
  USER_UPDATE_DETAILS_SUCCESS,
  USER_UPDATE_DETAILS_FAIL,
  USER_REGISTER_RESET,
  USER_DETAILS_RESET,
  USER_UPDATE_DETAILS_RESET,
  SPONSORS_REQUEST,
  SPONSORS_SUCCESS,
  SPONSORS_FAIL,
  USER_CV_REQUEST,
  USER_CV_SUCCESS,
  USER_CV_FAIL,
  RECOMMEND_USER_REQUEST,
  RECOMMEND_USER_SUCCESS,
  RECOMMEND_USER_FAIL,
} from "../constants/userConstants";
import {
  ADMISSION_CREATE_RESET,
  ADMISSION_DELETE_RESET,
  ADMISSION_DETAILS_RESET,
  ADMISSION_LIST_RESET,
  ADMISSION_RECURRENT_RESET,
  ADMISSION_UPDATE_RESET,
  DELETE_ADM_MED_RESET,
  NEW_ADM_MED_RESET,
  SORT_ADM_MED_RESET,
  UPDATE_ADM_MED_RESET,
} from "../constants/admissionConstants";
import {
  PATIENT_DETAILS_RESET,
  PATIENT_LIST_RESET,
  PATIENT_REGISTER_RESET,
  PATIENT_UPDATE_RESET,
} from "../constants/patientConstants";
import {
  LIST_USER_TEAMS_RESET,
  TEAM_CREATE_RESET,
  TEAM_DETAILS_RESET,
  TEAM_UPDATE_RESET,
} from "../constants/teamConstants";

import { TRADE_LABELS_RESET } from "../constants/drugConstants";

export const login = (inputs) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/users/login",
      { email: inputs.email, password: inputs.password, token: inputs.token },
      config
    );

    localStorage.setItem("userInfo", JSON.stringify(data));

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const registerUser = (newUser) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/users",
      {
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        phone: newUser.phone,
        password: newUser.password,
        token: newUser.token,
      },
      config
    );
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (
      message === "Not authorized, token failed" ||
      message === "User not found"
    ) {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const getUserCV = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_CV_REQUEST,
    });

    const { data } = await axios.get(`/api/users/reqUserCV/${id}`);

    dispatch({
      type: USER_CV_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (
      message === "Not authorized, token failed" ||
      message === "User not found"
    ) {
      dispatch(logout());
    }
    dispatch({
      type: USER_CV_FAIL,
      payload: message,
    });
  }
};

export const recommUser = (filters) => async (dispatch) => {
  try {
    dispatch({
      type: RECOMMEND_USER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/users/addRecomm/${filters.user}`,
      { filters },
      config
    );

    dispatch({
      type: RECOMMEND_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (
      message === "Not authorized, token failed" ||
      message === "User not found"
    ) {
      dispatch(logout());
    }
    dispatch({
      type: RECOMMEND_USER_FAIL,
      payload: message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("_grecaptcha");
  localStorage.removeItem("tradeLabels");

  dispatch({ type: TRADE_LABELS_RESET });

  dispatch({ type: ADMISSION_CREATE_RESET });
  dispatch({ type: ADMISSION_LIST_RESET });
  dispatch({ type: ADMISSION_RECURRENT_RESET });
  dispatch({ type: ADMISSION_DETAILS_RESET });
  dispatch({ type: ADMISSION_UPDATE_RESET });
  dispatch({ type: ADMISSION_DELETE_RESET });
  dispatch({ type: UPDATE_ADM_MED_RESET });
  dispatch({ type: SORT_ADM_MED_RESET });
  dispatch({ type: NEW_ADM_MED_RESET });
  dispatch({ type: DELETE_ADM_MED_RESET });

  dispatch({ type: PATIENT_REGISTER_RESET });
  dispatch({ type: PATIENT_DETAILS_RESET });
  dispatch({ type: PATIENT_LIST_RESET });
  dispatch({ type: PATIENT_UPDATE_RESET });

  dispatch({ type: TEAM_CREATE_RESET });
  dispatch({ type: TEAM_DETAILS_RESET });
  dispatch({ type: TEAM_UPDATE_RESET });
  dispatch({ type: LIST_USER_TEAMS_RESET });

  dispatch({ type: USER_ERR_TEMP_ADD_RESET });
  dispatch({ type: USER_ERR_TEMP_RESET });

  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_REGISTER_RESET });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: USER_UPDATE_DETAILS_RESET });
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/users/${userInfo._id}/edit`,
      user,
      config
    );

    dispatch({
      type: USER_UPDATE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const listSponsors = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPONSORS_REQUEST,
    });

    const { data } = await axios.get(`/api/users/listSponsors`);

    dispatch({
      type: SPONSORS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SPONSORS_FAIL,
      payload: message,
    });
  }
};
