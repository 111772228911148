export const TEAM_CREATE_REQUEST = "TEAM_CREATE_REQUEST";
export const TEAM_CREATE_SUCCESS = "TEAM_CREATE_SUCCESS";
export const TEAM_CREATE_FAIL = "TEAM_CREATE_FAIL";
export const TEAM_CREATE_RESET = "TEAM_CREATE_RESET";

export const TEAM_DETAILS_REQUEST = "TEAM_DETAILS_REQUEST";
export const TEAM_DETAILS_SUCCESS = "TEAM_DETAILS_SUCCESS";
export const TEAM_DETAILS_FAIL = "TEAM_DETAILS_FAIL";
export const TEAM_DETAILS_RESET = "TEAM_DETAILS_RESET";

export const TEAM_UPDATE_REQUEST = "TEAM_UPDATE_REQUEST";
export const TEAM_UPDATE_SUCCESS = "TEAM_UPDATE_SUCCESS";
export const TEAM_UPDATE_FAIL = "TEAM_UPDATE_FAIL";
export const TEAM_UPDATE_RESET = "TEAM_UPDATE_RESET";

export const LIST_USER_TEAMS_REQUEST = "LIST_USER_TEAMS_REQUEST";
export const LIST_USER_TEAMS_SUCCESS = "LIST_USER_TEAMS_SUCCESS";
export const LIST_USER_TEAMS_FAIL = "LIST_USER_TEAMS_FAIL";
export const LIST_USER_TEAMS_RESET = "LIST_USER_TEAMS_RESET";
