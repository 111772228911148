import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

const Counter = ({ limit, label }) => {
  let [progress, setProgress] = useState({ num: 0 });

  useEffect(() => {
    console.log("oo");
    setInterval(() => {
      setProgress((prevStat) => {
        return {
          num: prevStat.num < limit ? prevStat.num + 1 : prevStat.num,
        };
      });
    }, (1000 * 1) / limit);
  }, [limit]);
  return (
    <Row className="p-5">
      <Col>
        <Row className="justify-content-center">
          <div className="circle bg-success text-center">
            <h9>{progress.num}</h9>
          </div>
        </Row>
        <Row className="justify-content-center">
          <h3>{label}</h3>
        </Row>
      </Col>
    </Row>
  );
};

export default Counter;
