export const REVIEW_CREATE_REQUEST = "REVIEW_CREATE_REQUEST";
export const REVIEW_CREATE_SUCCESS = "REVIEW_CREATE_SUCCESS";
export const REVIEW_CREATE_FAIL = "REVIEW_CREATE_FAIL";
export const REVIEW_CREATE_RESET = "REVIEW_CREATE_RESET";

export const REVIEW_SEARCH_REQUEST = "REVIEW_SEARCH_REQUEST";
export const REVIEW_SEARCH_SUCCESS = "REVIEW_SEARCH_SUCCESS";
export const REVIEW_SEARCH_FAIL = "REVIEW_SEARCH_FAIL";
export const REVIEW_SEARCH_RESET = "REVIEW_SEARCH_RESET";

export const REVIEW_SEARCH_PROGRESS_REQUEST = "REVIEW_SEARCH_PROGRESS_REQUEST";
export const REVIEW_SEARCH_PROGRESS_SUCCESS = "REVIEW_SEARCH_PROGRESS_SUCCESS";
export const REVIEW_SEARCH_PROGRESS_FAIL = "REVIEW_SEARCH_PROGRESS_FAIL";
export const REVIEW_SEARCH_PROGRESS_RESET = "REVIEW_SEARCH_PROGRESS_RESET";

export const REVIEWS_PER_TAG_REQUEST = "REVIEWS_PER_TAG_REQUEST";
export const REVIEWS_PER_TAG_SUCCESS = "REVIEWS_PER_TAG_SUCCESS";
export const REVIEWS_PER_TAG_FAIL = "REVIEWS_PER_TAG_FAIL";
export const REVIEWS_PER_TAG_RESET = "REVIEWS_PER_TAG_RESET";

export const REV_HEAD_UPDATE_REQUEST = "REV_HEAD_UPDATE_REQUEST";
export const REV_HEAD_UPDATE_SUCCESS = "REV_HEAD_UPDATE_SUCCESS";
export const REV_HEAD_UPDATE_FAIL = "REV_HEAD_UPDATE_FAIL";
export const REV_HEAD_UPDATE_RESET = "REV_HEAD_UPDATE_RESET";

export const REV_MED_UPDATE_REQUEST = "REV_MED_UPDATE_REQUEST";
export const REV_MED_UPDATE_SUCCESS = "REV_MED_UPDATE_SUCCESS";
export const REV_MED_UPDATE_FAIL = "REV_MED_UPDATE_FAIL";
export const REV_MED_UPDATE_RESET = "REV_MED_UPDATE_RESET";

export const REV_MED_ADD_REQUEST = "REV_MED_ADD_REQUEST";
export const REV_MED_ADD_SUCCESS = "REV_MED_ADD_SUCCESS";
export const REV_MED_ADD_FAIL = "REV_MED_ADD_FAIL";
export const REV_MED_ADD_RESET = "REV_MED_ADD_RESET";

export const REV_MED_DEL_REQUEST = "REV_MED_DEL_REQUEST";
export const REV_MED_DEL_SUCCESS = "REV_MED_DEL_SUCCESS";
export const REV_MED_DEL_FAIL = "REV_MED_DEL_FAIL";
export const REV_MED_DEL_RESET = "REV_MED_DEL_RESET";

export const ADMISSION_REVIEWS_REQUEST = "ADMISSION_REVIEWS_REQUEST";
export const ADMISSION_REVIEWS_SUCCESS = "ADMISSION_REVIEWS_SUCCESS";
export const ADMISSION_REVIEWS_FAIL = "ADMISSION_REVIEWS_FAIL";
export const ADMISSION_REVIEWS_RESET = "ADMISSION_RESET_RESET";

export const ERR_TYPE_DIST_REQUEST = "ERR_TYPE_DIST_REQUEST";
export const ERR_TYPE_DIST_SUCCESS = "ERR_TYPE_DIST_SUCCESS";
export const ERR_TYPE_DIST_FAIL = "ERR_TYPE_DIST_FAIL";
export const ERR_TYPE_DIST_RESET = "ERR_TYPE_DIST_RESET";

export const REVIEW_DELETE_REQUEST = "REVIEW_DELETE_REQUEST";
export const REVIEW_DELETE_SUCCESS = "REVIEW_DELETE_SUCCESS";
export const REVIEW_DELETE_FAIL = "REVIEW_DELETE_FAIL";
export const REVIEW_DELETE_RESET = "RESET_DELETE_RESET";

export const LIST_REVEWS_PER_DAY_REQUEST = "LIST_REVEWS_PER_DAY_REQUEST";
export const LIST_REVEWS_PER_DAY_SUCCESS = "LIST_REVEWS_PER_DAY_SUCCESS";
export const LIST_REVEWS_PER_DAY_FAIL = "LIST_REVEWS_PER_DAY_FAIL";
export const LIST_REVEWS_PER_DAY_RESET = "LIST_REVEWS_PER_DAY_RESET";

export const RATING_ERR_REQUEST = "RATING_ERR_REQUEST";
export const RATING_ERR_SUCCESS = "RATING_ERR_SUCCESS";
export const RATING_ERR_FAIL = "RATING_ERR_FAIL";
export const RATING_ERR_RESET = "RATING_ERR_RESET";

export const REV_MED_DASHBOARD_REQUEST = "REV_MED_DASHBOARD_REQUEST";
export const REV_MED_DASHBOARD_SUCCESS = "REV_MED_DASHBOARD_SUCCESS";
export const REV_MED_DASHBOARD_FAIL = "REV_MED_DASHBOARD_FAIL";
export const REV_MED_DASHBOARD_RESET = "REV_MED_DASHBOARD_RESET";
