import React, { useEffect, useState } from "react";
import UploadWidget from "../components/UploadWidget";
import { Accordion, Button, Card, Form, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateAdmission } from "../actions/admissionActions";
import { ADMISSION_DETAILS_RESET } from "../constants/admissionConstants";

const AdmGallery = ({ admissionId }) => {
  const [title, setTitle] = useState("");
  const [images, setImages] = useState([]);
  const [gallery, setGallery] = useState([]);

  const admissionDetails = useSelector((state) => state.admissionDetails);
  const { admission } = admissionDetails;

  const dispatch = useDispatch();

  const updateGallery = () => {
    dispatch(
      updateAdmission({
        _id: admissionId,
        admissionDate: admission.admissionDate,
        admissionDetails: admission.admissionDetails,
        dischargeDate: admission.dischargeDate,
        dischargeNote: admission.dischargeNote,
        dischargeStatus: admission.dischargeStatus,
        gallery: [
          ...gallery,
          {
            title,
            images,
          },
        ],
      })
    );
    dispatch({ type: ADMISSION_DETAILS_RESET });
  };
  useEffect(() => {
    if (admission) {
      setGallery(admission.gallery);
    }
  }, [admission]);
  return (
    <div className="my-3">
      <Accordion>
        {admission &&
          gallery.map((i) => (
            <div key={i}>
              <Accordion.Toggle
                eventKey={i._id}
                as={Card.Header}
                variant="primary"
              >
                <h3>{i.title}</h3>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={i._id}>
                <>
                  {" "}
                  {i.images.map((im) => (
                    <Row>
                      <Image src={im.image} fluid />
                    </Row>
                  ))}
                </>
              </Accordion.Collapse>
            </div>
          ))}
      </Accordion>

      <Form>
        <h1>Add New Images</h1>
        <Form.Group controlId="title" className="col-sm-12">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          ></Form.Control>
        </Form.Group>
        {title && (
          <UploadWidget
            title={title}
            images={images}
            setImages={setImages}
            setGallery={setGallery}
            gallery={gallery}
          />
        )}
        <Row className="justify-content-end">
          <Button onClick={() => updateGallery()}>Save</Button>
        </Row>
      </Form>
    </div>
  );
};

export default AdmGallery;
