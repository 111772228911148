import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  FormLabel,
  Button,
  FormGroup,
  Container,
  Row,
  Col,
  Accordion,
  Card,
} from "react-bootstrap";
import specialityList from "../data/speciality";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Message from "../components/Message";
import { createTeam, listUserTeams } from "../actions/teamActions";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import TextEditor from "../components/TextEditor";
import Counter from "../components/Counter";
import { getUserDetails } from "../actions/userActions";

const TeamListScreen = () => {
  const [name, setName] = useState("");
  const [admin, setAdmin] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [about, setAbout] = useState("");
  const [members, setMembers] = useState([]);

  const [message, setMessage] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const teamAddStore = useSelector((state) => state.teamAddStore);
  const { loading } = teamAddStore;

  const teamsOfUserStore = useSelector((state) => state.teamsOfUserStore);
  const { loading: loadingTeams, userTeams, error } = teamsOfUserStore;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = () => {
    dispatch(createTeam({ name, admin, specialty, about, members }));
  };
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      if (!user) {
        dispatch(getUserDetails(userInfo._id));
      }
      if (!admin) {
        setAdmin(userInfo.email);
        setMembers([{ id: Math.random(), user: userInfo.email }]);
        if (!userTeams) {
          dispatch(listUserTeams());
        }
      }
    }
    if (error) {
      setMessage(error);
    }
  }, [userInfo, userTeams, dispatch, admin, navigate, user]);
  return (
    <>
      <FormContainer>
        <BreadcrumbComponent
          titles={[{ idx: "1", label: "Home", path: `/`, active: true }]}
        />

        {!user || loadingTeams || loading ? (
          <Loader />
        ) : error ? (
          <Message
            children={message}
            variant="danger"
            setMessgae={setMessage}
          />
        ) : (
          <>
            <h2 className="text-primary">{`Welcome Dr. ${user.firstName} ${user.lastName}`}</h2>{" "}
            <h3>Your Metrics</h3>
            <strong className="row justify-content-center">
              {`Start reviewing: ${user.start} ago`}
            </strong>
            <Row>
              <Col className="col-lg-6">
                <Counter limit={user.patients} label="Patients" />
              </Col>
              <Col className="col-lg-6">
                <Counter limit={user.reviews} label="Reviews" />
              </Col>
            </Row>
            <h3>Your Teams</h3>
            {userTeams &&
              userTeams.map((t) => (
                <div key={t._id}>
                  <Container
                    style={{
                      backgroundColor: "#00abff",
                    }}
                    className=" mt-3 text-white border rounded"
                  >
                    <Row
                      className="p-3"
                      onClick={() => {
                        navigate(`/team/${t._id}`);
                      }}
                    >
                      <Col className="row justify-content-center" key={t._id}>
                        <h1>{t.name}</h1>
                      </Col>
                      <Col className="col-sm-2 align-self-center ">
                        <span>
                          <i className="fas fa-external-link-alt"></i>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ))}
          </>
        )}
        <Accordion className="py-3" defaultActiveKey="0">
          <Accordion.Toggle
            className="bg-secondary collapsed"
            eventKey="0"
            as={Card.Header}
            variant="link"
          >
            <Row className="justify-content-center text-white">
              <i className="fas fa-angle-double-down pt-1 pr-2"></i>CREATE NEW
              TEAM
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Form onSubmit={submitHandler} className="py-3">
              <Form.Group controlId="teamName" className="col-sm-12">
                <Form.Label>Team Name </Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter team name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="speciality" className="col-sm-12">
                <FormLabel>Specialty</FormLabel>
                <select
                  className="custom-select"
                  type="name"
                  value={specialty}
                  onChange={(e) => setSpecialty(e.target.value)}
                >
                  {specialityList.map((specialityItem) => (
                    <option
                      key={specialityItem._id}
                      value={specialityItem._id}
                      disabled={specialityItem.disabled}
                    >
                      {specialityItem.name}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <FormGroup className="col-sm-12">
                <Form.Label>About</Form.Label>
                <div className="text-dark">
                  <TextEditor text={about} setText={setAbout} />
                </div>
              </FormGroup>
              <FormGroup className="row justify-content-end">
                <Button type="submit" className="mr-5" variant="primary">
                  Create Team
                </Button>
              </FormGroup>
            </Form>
          </Accordion.Collapse>
        </Accordion>
      </FormContainer>
    </>
  );
};

export default TeamListScreen;
