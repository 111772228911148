import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmMed } from "../actions/admissionActions";
import { DELETE_ADM_MED_RESET } from "../constants/admissionConstants";
import Message from "./Message";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";

const AdmMedUnit = ({
  medication,
  setMedVehicle,
  medVehicle,
  refresh,
  setRefresh,
  editPermission,
}) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const admMedDelStore = useSelector((state) => state.admMedDelStore);
  const { noRequest, message, error: deleteErr } = admMedDelStore;

  const admMedUpdateStore = useSelector((state) => state.admMedUpdateStore);
  const { noRequest: noUpdateRequest, error: updateErr } = admMedUpdateStore;

  const dispatch = useDispatch();

  const handleDelete = (m) => {
    if (noRequest) {
      medication.edit = "load";
      dispatch(deleteAdmMed(m._id));
    } else {
      alert(
        "Please wait while system finish doing previous delete, you can edit or add another medication"
      );
    }
  };
  const handleEdit = (m) => {
    if (noUpdateRequest) {
      m.edit = true;
      setRefresh(!refresh);
    } else {
      alert(
        "Please wait while system finish doing previous update, you can edit or delete another medication"
      );
    }
  };
  useEffect(() => {
    if (message) {
      medication.edit = false;
      setMedVehicle(medVehicle.filter((med) => med !== medication));
      setRefresh(!refresh);
      dispatch({ type: DELETE_ADM_MED_RESET });
    }
  }, [message]);

  return (
    <>
      {deleteErr && <Message children={deleteErr} />}

      {updateErr && <Message children={updateErr} />}

      {medication.edit === "load" ? (
        <Skeleton height="100px" />
      ) : (
        <Row className="card row mt-2 p-3 border-primary text-primary">
          <Row>
            <Col>
              <FormCheckInput className="ml-3" type="checkbox"></FormCheckInput>{" "}
            </Col>
            <Col
              className="col-sm-8"
              style={{ textDecoration: medication.endDate && "line-through" }}
              onClick={() =>
                userInfo.email === "admin@imc.com" &&
                window.open(
                  `https://www.clinicbook.org/drugs?tradeId=${medication.medicationTrade}`,
                  "_blank"
                )
              }
            >
              {medication.tradeLabel}
            </Col>

            <Col>
              <Row className="row justify-content-start">
                <span>
                  {`${medication.duration} days:`}
                  {medication.startDate &&
                    ` Start ${medication.startDate.substring(5, 10)}`}
                  {medication.endDate &&
                    ` and end ${medication.endDate.substring(5, 10)}`}
                </span>
              </Row>
            </Col>
          </Row>
          <Row className=" text-dark">
            <Col
              dangerouslySetInnerHTML={{
                __html: `${medication.note}`,
              }}
            />
          </Row>
          <Row>
            {editPermission && (
              <Col
                className="col-sm-6 text-danger"
                onClick={() => handleDelete(medication)}
              >
                <strong className="row justify-content-center p-2 ">
                  <i className="fas fa-trash-alt pr-2"></i> Delete
                </strong>
              </Col>
            )}
            {editPermission && (
              <Col className="col-sm-6" onClick={() => handleEdit(medication)}>
                <strong className="row justify-content-center p-2 ">
                  <i className="far fa-edit pr-2"></i> Edit
                </strong>
              </Col>
            )}
          </Row>
        </Row>
      )}
    </>
  );
};

export default AdmMedUnit;
