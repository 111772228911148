import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "./FormContainer";
import { listDrugErrTypes } from "../actions/errTypeActions";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { addNewDrug, listDrugRoots, updateRoot } from "../actions/drugActions";
import Loader from "./Loader";
import Message from "./Message";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { listTags } from "../actions/tagActions";
import { Typeahead } from "react-bootstrap-typeahead";

const DrugForm = ({ title, reqTrade }) => {
  const [rootId, setRootId] = useState();
  const [label, setLabel] = useState("");
  const [tradeLabels, setTradeLabels] = useState([
    {
      idx: 1,
      label: "",
    },
  ]);

  const [compatibility, setCompatibility] = useState([
    {
      idx: 1,
      drug: "",
      category: "",
      note: "",
    },
  ]);

  const [errTemps, setErrTemps] = useState([
    {
      idx: 1,
      errType: "",
      isAdult: true,
      label: "",
    },
  ]);

  const [tags, setTags] = useState([
    {
      idx: 0,
      tag: "",
    },
  ]);

  const [tips, setTips] = useState();

  const [message, setMessage] = useState("");

  const errTypes = useSelector((state) => state.errTypes);
  const { noRequest: noErrTypeRequest, errTypeList } = errTypes;

  const newDrugStore = useSelector((state) => state.newDrugStore);
  const { loading, drug: addedDrug } = newDrugStore;

  const rootByTradeIdStore = useSelector((state) => state.rootByTradeIdStore);
  const { drug: drugRoot } = rootByTradeIdStore;

  const updateRootStore = useSelector((state) => state.updateRootStore);
  const { loading: loadingUpdatedRoot, drug: updatedRoot } = updateRootStore;

  const tagListStore = useSelector((state) => state.tagListStore);
  const { tagList, noRequest: noTagRequest } = tagListStore;

  const handleTradeLabel = (e, trade) => {
    trade.label = e.target.value;
  };

  const handleErrTempNote = (data, err) => {
    err.label = data;
  };

  const addTradeRow = () => {
    setTradeLabels([
      ...tradeLabels,
      {
        idx: Math.random(),
        label: "",
      },
    ]);
  };
  const deleteTradeRow = (label) => {
    setTradeLabels(tradeLabels.filter((t) => t !== label));
  };

  const newErrRow = () => {
    setErrTemps([
      ...errTemps,
      {
        idx: Math.random(),
        errType: "",
        isAdult: true,
        label: "",
      },
    ]);
  };
  const delErrRow = (err) => {
    setErrTemps(errTemps.filter((e) => e !== err));
  };

  const handleErrType = (e, err) => {
    err.errType = e.target.value;
  };

  const handleTags = (e, t) => {
    if (e.length !== 0) {
      t.tag = e["0"]._id;
    }
  };

  const addTagRow = () => {
    setTags([
      ...tags,
      {
        idx: 0,
        tag: "",
      },
    ]);
  };
  const deleteTagRow = (t) => {
    setTags(tags.filter((ts) => ts !== t));
  };
  const handleErrAge = (e, err) => {
    err.isAdult = !err.isAdult;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      addNewDrug({
        label,
        tradeLabels,
        errTemps,
        tags,
      })
    );
  };
  const updateHandler = (e) => {
    e.preventDefault();
    console.log(tags);
    dispatch(
      updateRoot({
        _id: rootId,
        label,
        tradeLabels,
        errTemps,
        tips,
        tags,
      })
    );
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (drugRoot) {
      setRootId(drugRoot._id);
      setLabel(drugRoot.label);
      setTradeLabels(
        drugRoot.tradeLabels || [
          {
            idx: 1,
            label: "",
          },
        ]
      );
      setErrTemps(
        drugRoot.errTemps || [
          {
            idx: 1,
            errType: "",
            isAdult: true,
            label: "",
          },
        ]
      );
      setCompatibility(
        drugRoot.compatibility || [
          {
            idx: 1,
            drug: "",
            category: "",
            note: "",
          },
        ]
      );
      setTips(drugRoot.tips || "");
      setTags(
        drugRoot.tags
          ? drugRoot.tags
          : [
              {
                idx: 0,
                tag: "",
              },
            ]
      );
    }
    if (noErrTypeRequest) {
      dispatch(listDrugErrTypes());
    }
    if (noTagRequest) {
      dispatch(listTags());
    }
    if (addedDrug) {
      setMessage("Drug added");
    }
    if (updatedRoot) {
      setMessage("Drug updated");
    }
  }, [label, noErrTypeRequest, errTypeList, addedDrug]);

  return (
    <FormContainer>
      {reqTrade && !rootId ? (
        <Loader />
      ) : (
        <Form>
          <h1 className="row justify-content-center">{title}</h1>
          <FormGroup controlId="label">
            <FormLabel>Drug Root Name</FormLabel>
            <FormControl
              type="text"
              placeholder="e.g.: Paracetamol"
              defaultValue={label}
              onChange={(e) => setLabel(e.target.value)}
            ></FormControl>
          </FormGroup>

          <Form.Label>Trade Label</Form.Label>
          {tradeLabels.map((trade) => (
            <div key={trade.idx}>
              <Row>
                {" "}
                <Col className="col-8">
                  <FormGroup>
                    <Form.Control
                      type="text"
                      placeholder="e.g.: Paramol 500 mg tab"
                      defaultValue={trade.label}
                      onChange={(e) => handleTradeLabel(e, trade)}
                    ></Form.Control>
                  </FormGroup>
                </Col>
                <Col className="col-4">
                  <Button onClick={() => addTradeRow()}>
                    <i className="fa fa-plus-circle" aria-hidden="true" />
                  </Button>
                  {trade.idx !== 1 && (
                    <Button
                      className="btn-danger ml-1"
                      onClick={() => deleteTradeRow(trade)}
                    >
                      <i className="fa fa-minus" aria-hidden="true" />
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          ))}

          <Form.Label>Error Templates</Form.Label>
          {errTemps.map((err) => (
            <Card key={err.idx} className="border-dark">
              <Card.Body>
                <Row>
                  <Col className="col-7">
                    <FormGroup>
                      <select
                        className="custom-select"
                        type="text"
                        defaultValue={err.errType}
                        onChange={(e) => handleErrType(e, err)}
                      >
                        {errTypeList &&
                          errTypeList.map((t) => (
                            <option key={t._id} value={t._id}>
                              {t.label}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col className="col-2">
                    <div className="row align-items-center">
                      <FormGroup className="col-sm-6">
                        <FormCheckInput
                          defaultChecked={err.isAdult}
                          onChange={(e) => handleErrAge(e, err)}
                          type="checkbox"
                        ></FormCheckInput>{" "}
                        <FormLabel>Adult</FormLabel>{" "}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col className="col-3">
                    <Button onClick={() => newErrRow()}>
                      <i className="fa fa-plus-circle" aria-hidden="true" />
                    </Button>
                    {err.idx !== 1 && (
                      <Button
                        className="btn-danger ml-2"
                        onClick={() => delErrRow(err)}
                      >
                        <i className="fa fa-minus" aria-hidden="true" />
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 text-dark">
                    {" "}
                    <CKEditor
                      editor={ClassicEditor}
                      data={err.label}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleErrTempNote(data, err);
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))}

          <Form.Label>Drug Tags</Form.Label>

          {tags &&
            tagList &&
            tags.map((t, order = 0) => (
              <Row key={order}>
                <Row>
                  <Col className="col-sm-9">
                    <Typeahead
                      placeholder="Enter Medication"
                      defaultInputValue={
                        t.tag &&
                        tagList.find((tl) => tl._id === t.tag.toString()).label
                      }
                      options={tagList}
                      onChange={(e) => handleTags(e, t)}
                      id="tags"
                    />
                  </Col>
                  {order > 0 && (
                    <Col className="col-sm-3">
                      <Button
                        onClick={() => deleteTagRow(t)}
                        className="font-weight-bold btn-danger"
                      >
                        -
                      </Button>
                    </Col>
                  )}
                </Row>
              </Row>
            ))}

          <Row className="row justify-content-end">
            <Button onClick={() => addTagRow()} className="font-weight-bold">
              +
            </Button>
          </Row>

          <Form.Label className="mt-2 text-bold">Tips</Form.Label>

          <Row>
            <Col className="col-12">
              <CKEditor
                editor={ClassicEditor}
                data={tips}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTips(data);
                }}
              />
            </Col>
          </Row>

          <Row className="py-3 justify-content-end mr-2">
            {drugRoot ? (
              <Button onClick={(e) => updateHandler(e)}>Update</Button>
            ) : (
              <Button onClick={(e) => submitHandler(e)}>Submit New Drug</Button>
            )}
          </Row>
        </Form>
      )}
      {loading && <Loader />}
      {loadingUpdatedRoot && <Loader />}
      {addedDrug && (
        <Message children={message} variant="success" setMessgae={setMessage} />
      )}
    </FormContainer>
  );
};

export default DrugForm;

{
  /* 
          <Form.Label className="pt-2 text-bold">Compatibility</Form.Label>
          {compatibility.map((drug) => (
            <Card key={drug.idx} className="border-dark my-2">
              <Card.Body>
                <Row>
                  <Col className="col-12">
                    {!drugRoots ? (
                      <Skeleton height="50px" className="mb-1" />
                    ) : (
                      <Typeahead
                        className="py-2"
                        placeholder="Enter Medication"
                        options={drugRoots}
                        onChange={(e) => handleCompatabilityDrug(e, drug)}
                        id="errDrug"
                      />
                    )}
                  </Col>

                  <Col className="col-12">
                    <CKEditor
                      editor={ClassicEditor}
                      data={compatibility.note}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        handleCompatabilityNote(data, drug)
                      }}
                    />
                  </Col>

                  <Col className="col-4">
                    <Button onClick={() => addCompatibilityRow()}>
                      <i className="fa fa-plus-circle" aria-hidden="true" />
                    </Button>
                    {compatibility.idx !== 1 && (
                      <Button
                        className="btn-danger ml-1"
                        onClick={() => deleteCompatibilityRow(drug)}>
                        <i className="fa fa-minus" aria-hidden="true" />
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))} */
}
// const handleCompatabilityDrug = (e, drug) => {
//   if (e.length !== 0) {
//     drug.drug = e['0']._id
//   }
// }

// const handleCompatabilityNote = (data, drug) => {
//   drug.note = data
// }

// const deleteCompatibilityRow = (label) => {
//   setCompatibility(compatibility.filter((t) => t !== label))
// }

// const addCompatibilityRow = () => {
//   setCompatibility([
//     ...compatibility,
//     {
//       idx: Math.random(),
//       drug: '',
//       category: '',
//       note: '',
//     },
//   ])
// }
