import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import AdmMedUnit from "./AdmMedUnit";
import AdmMedForm from "./AdmMedForm";
import { listTradeLabels } from "../actions/drugActions";

// import tradeLabels from '../data/tradeLabels'

const AdmissionMedications = ({ admissionId, editPermission }) => {
  const [medVehicle, setMedVehicle] = useState();
  const [offMed, setOffMed] = useState(false);
  const [offMedList, setOffMedList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const tradeLabelsStore = useSelector((state) => state.tradeLabelsStore);
  const { noRequest } = tradeLabelsStore;

  const dispatch = useDispatch();

  if (!localStorage.tradeLabels && noRequest) {
    dispatch(listTradeLabels());
  }

  let tradeLabels =
    localStorage.tradeLabels && JSON.parse(localStorage.tradeLabels);

  const admissionDetails = useSelector((state) => state.admissionDetails);
  const { admission } = admissionDetails;

  const handleMedHide = () => {
    if (offMed === false) {
      setOffMedList(medVehicle.filter((m) => m.endDate !== null));
      setMedVehicle(medVehicle.filter((m) => m.endDate === null));
      setOffMed(true);
    } else {
      setMedVehicle(offMedList.concat(medVehicle));
      setOffMed(false);
    }
  };

  const addMedication = () => {
    setMedVehicle([
      ...medVehicle,
      {
        order:
          medVehicle.length > 0
            ? medVehicle[medVehicle.length - 1].order + 1
            : 0,
        admissionId,
        idx: 0,
        medicationTrade: "",
        tradeLabel: "",
        medicationRoot: "",
        startDate: "",
        endDate: "",
        note: "",
        edit: true,
      },
    ]);
  };

  useEffect(() => {
    if (admissionId !== admission._id) {
      setMedVehicle();
    }

    if (!medVehicle) {
      if (admission) {
        setMedVehicle(
          admission.medications
            .sort((a, b) => a.order - b.order)
            .map((m) => {
              return {
                order: m.order,
                admissionId,
                _id: m._id,
                medicationTrade: m.medicationTrade,
                medicationRoot: m.medicationRoot,
                tradeLabel: m.tradeLabel,
                startDate: m.startDate,
                endDate: m.endDate,
                note: m.note,
                duration: m.duration,
                edit: false,
              };
            })
        );
      }
    }
  }, [admission, admissionId, medVehicle, refresh]);
  return (
    <div>
      {!admission && !tradeLabels ? (
        <Loader />
      ) : (
        <>
          <Row>
            <Col>
              {medVehicle && medVehicle.length > 1 && (
                <>
                  <Form.Check
                    className="my-3"
                    type="switch"
                    id="offMed"
                    onChange={() => handleMedHide()}
                    label="Hide Off Medications"
                    checked={offMed}
                  />
                </>
              )}
            </Col>
          </Row>

          {medVehicle &&
            medVehicle.map((m) => (
              <div key={m._id}>
                {m.edit === false ? (
                  <AdmMedUnit
                    medication={m}
                    admissionId={admissionId}
                    medVehicle={medVehicle}
                    setMedVehicle={setMedVehicle}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    editPermission={editPermission}
                  />
                ) : (
                  <AdmMedForm
                    medication={m}
                    admissionId={admissionId}
                    tradeLabels={tradeLabels}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    medVehicle={medVehicle}
                    setMedVehicle={setMedVehicle}
                  />
                )}
              </div>
            ))}
          {editPermission && (
            <Accordion.Toggle
              className="bg-secondary text-white my-2"
              onClick={() => addMedication()}
              as={Card.Header}
              variant="link"
            >
              <Row className="row justify-content-center">
                CLICK HERE TO ADD NEW MEDICATION
              </Row>
            </Accordion.Toggle>
          )}
        </>
      )}
    </div>
  );
};

export default AdmissionMedications;
