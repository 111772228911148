import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { getRevMedDashboard } from "../actions/reviewActions";

const AdmMedList = ({ admissionId }) => {
  const [dayList, setDayList] = useState();
  const [medications, setMedications] = useState();

  const revMedDashboardStore = useSelector(
    (state) => state.revMedDashboardStore
  );
  const { medDashboard, error } = revMedDashboardStore;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!medDashboard || medDashboard.admission !== admissionId) {
      console.log("exist");
      dispatch(getRevMedDashboard(admissionId));
    } else {
      setDayList(medDashboard.dayList);
      setMedications(medDashboard.zMedications);
    }
  }, [medDashboard]);

  return (
    <div className=" overflow-auto flex-nowrap">
      {dayList && medications && (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Medication</th>
              {dayList &&
                dayList.map((d) => (
                  <th className="px-2 bg-light sticky-top top-0">
                    {d.substring(5, 10)}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {medications.map((m) => (
              <tr>
                {" "}
                <td
                  style={{
                    position: "sticky",
                  }}
                >
                  {m.medication}
                </td>
                {m.days.map((med) => (
                  <td className="text-primary">
                    {med.isChecked ? med.dayDate.substring(5, 10) : ""}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default AdmMedList;
